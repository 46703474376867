<template>
  <div>
    <NewPaymentsPayoneerHead
        :showFilter="showFilter"
        :countFilterParams="countFilterParams"
        :navTabs="navTabs"
        @toggleFilter="toggleFilter"
        @changeTab="(data) => $emit('changeTab', data)"
        @changeFilter="data => {$emit('changeFilter', data)}"
        @massPay="$emit('massPay')"
    />

    <div class="table-filter-wrap">

      <NewPaymentsPayoneerFilter
          :showFilter="showFilter"
          :filterGetParams="filterGetParams"
          @close="closeFilter"
          @changeFilter="data => {$emit('changeFilter', data)}"
          @resetFilter="$emit('resetFilter')"
      />

      <NewPaymentsPayoneerTable
          :countFilterParams="countFilterParams"
          :navTabs="navTabs"
          @resetFilter="$emit('resetFilter')"
          @downloadFiles="type => $emit('downloadFiles', type)"
          @showMore="$emit('showMore')"
          @filter="$emit('filter')"
          @reload="$emit('reload')"
      />
    </div>
  </div>
</template>

<script>


  import NewPaymentsPayoneerHead from "./NewPaymentsPayoneerHead/NewPaymentsPayoneerHead";
  import NewPaymentsPayoneerFilter from "./NewPaymentsPayoneerFilter/NewPaymentsPayoneerFilter";
  import NewPaymentsPayoneerTable from "./NewPaymentsPayoneerTable/NewPaymentsPayoneerTable";

  export default {
    name: "NewPaymentsPayoneerTableAdmin",

    components: {
      NewPaymentsPayoneerHead,
      NewPaymentsPayoneerFilter,
      NewPaymentsPayoneerTable,
    },

    props: {
      filterGetParams: Object,
      countFilterParams: Number,
      navTabs: Object,
    },

    data(){
      return{
        showFilter: false,
      }
    },

    methods: {
      closeFilter() {
        this.showFilter = false
      },

      toggleFilter() {
        this.showFilter = !this.showFilter
      },
    }
  }
</script>

<style scoped>

</style>
