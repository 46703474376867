<template>
  <modal
      @close="$emit('close')"
      class="custom-popup-modal custom-popup statistic-popup"
  >
    <template slot="header">
      {{ getUserName }}
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <div class="statistic-popup__price">
          $ {{ getUserBalance }}
        </div>
        <div class="block-table">
          <div class="block-table__row">
            <div class="block-table__col block-table__col--50">
              <div class="block-table__label" v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_phone'])"></div>
                {{$t('common_phone.localization_value.value')}}
              </div>
              <div class="block-table__content">{{ getUserPhone }}</div>
            </div>
            <div class="block-table__col block-table__col--50">
              <div class="block-table__label" v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_email'])"></div>
                {{$t('common_email.localization_value.value')}}
              </div>
              <div class="block-table__content">{{ getUserEmail }}</div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from "../../../../../commonModals/Modal";

  export default {
    name: "StatisticPopup",
    components: {
      Modal,

    },

    props: {
      userId: Number,
    },

    data() {
      return {
        user: {}
      }
    },

    mounted() {
      this.$store.dispatch('fetchUserItem', this.userId).then(response => {
        this.user = this.getRespData(response)
      })
    },

    computed: {
      getUserBalance() {
        return this.user?.balance
      },
      getUserName() {
        return this.user?.contacts?.[0]?.user_full_name
      },
      getUserPhone() {
        return this.user?.contacts?.[0]?.phone
      },
      getUserEmail() {
        return this.user?.contacts?.[0]?.email
      },
    },

    methods: {}
  }
</script>

<style scoped lang="scss">
  @import "../../../../../../scss/colors";

  .statistic-popup {
    &__price{
      margin-bottom: 24px;
      font-size: 14px;
      line-height: 16px;
      color: $black;
    }

    .modal-component__inner{
      max-width: 484px;
    }

    .modal-component__header{
      margin-bottom: 5px;
    }
  }
</style>