var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sum-input",class:{
      'sum-input--dollar' : _vm.icoType == 'dollar',
      'sum-input--euro' : _vm.icoType == 'euro',
      'sum-input--cm' : _vm.icoType == 'cm',
      'sum-input--inc' : _vm.icoType == 'inc',
      'disabled' : _vm.disabled === true
    }},[_c('label',{staticClass:"sum-input__label"},[_c('span',{class:{'white-space-pre-wrap' : _vm.preWrap}},[_vm._v(_vm._s(_vm.label))]),_c('input',{directives:[{name:"money",rawName:"v-money",value:(_vm.money),expression:"money"}],staticClass:"sum-input__input",attrs:{"type":"text","id":'default-input-' + _vm.id,"name":`name-` + _vm.id,"placeholder":_vm.placeholder,"disabled":_vm.disabled == true},domProps:{"value":_vm.sumValue},on:{"change":_vm.sumInputChange,"input":_vm.sumInput,"blur":_vm.focusOut}})]),(_vm.caption)?_c('span',{staticClass:"sum-input__caption"},[_vm._v(_vm._s(_vm.caption))]):_vm._e(),(_vm.error && _vm.errorTxt)?_c('span',{staticClass:"textarea-default__error error-field"},[_vm._v(_vm._s(_vm.errorTxt))]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }