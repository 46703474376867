
export const queryFilterMixin = {

  methods: {
    createQueryBuilderObject(params){
      const query = new this.Query();
      console.log(params);
      // if(params?.withUserNewMark){
      //   query.appends('show_n_mark')
      // }

      return query.for('example')
    },

    generateGetParamsFromQuery(myQuery, forPage, page){
      return '?' + myQuery.limit(forPage).page(parseInt(page)).url().split('?')[1]
    }
  }
}
