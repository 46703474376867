import {
  NEW_PAYMENTS_PAYONEER_STATUSES
} from "../../staticData/staticVariables";
import {checkAccess} from "@/mixins/userCredentionalMixins/userCredentional";

export const newPaymentsPayoneerMixin = {

  mixins: [checkAccess],

  methods: {

    async createPayment(pay = false, deferred = false) {
      if(!this.NPP.newPaymentsPayoneerValidate()) return

      let payPayment = deferred || pay

      if(payPayment) {
        await this.$store.dispatch('checkExpired2faAt').then(response => {
          payPayment = response.status
        })
      }

      let data = this.NPP.newPaymentsPayoneerPrepare(payPayment)

      return this.$store.dispatch('createNewPaymentsPayoneer', data).then((response) => {

        switch (this.getResponseStatus(response)) {
          /**
           * Success
           */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            if(payPayment){
              let isAccess = this.newPaymentPayoneerAccess(response.data.data.transaction.id)
              if(isAccess) {
                this.$router.push(this.$store.getters.GET_PATHS.financeNewPaymentsPayoneer + `?payId=${response.data.data.transaction.id}`)
              }
              return
            }

            this.$router.push(this.$store.getters.GET_PATHS.financeNewPaymentsPayoneer)
            this.openNotify('success', 'common_notificationRecordCreated')

            break
          }
          /**
           * Validation Error
           */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
          /**
           * Undefined Error
           */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }

        return response
      })
    },

    // async createRemainedPayment(pay = false, deferred = false, notRedirect = false) {
    //   if(!this.NPP.newPaymentsValidate()) return
    //
    //   let payPayment = deferred || pay
    //
    //   if(payPayment) {
    //     await this.$store.dispatch('checkExpired2faAt').then(response => {
    //       payPayment = response.status
    //     })
    //   }
    //
    //   let data = this.NPP.newPaymentsPrepare(payPayment)
    //
    //   return this.$store.dispatch('createRequestFundsRemainedTrns', data).then((response) => {
    //     switch (this.getResponseStatus(response)) {
    //       /**
    //        * Success
    //        */
    //       case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
    //         if(!payPayment && (deferred || pay)){
    //           this.newPaymentPayoneerAccess(response.data.data.transaction.id)
    //           return
    //         }
    //
    //         if(notRedirect) {
    //           this.openNotify('success', 'common_notificationRecordCreated')
    //           return response
    //         }
    //
    //         this.$router.push(this.$store.getters.GET_PATHS.cusPayments + '?page=1&count=25&type=balanceOutput')
    //         this.openNotify('success', 'common_notificationRecordCreated')
    //         break
    //       }
    //       /**
    //        * Validation Error
    //        */
    //       case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
    //         let errors = response.response.data.errors;
    //         this.notifyErrorHelper(errors)
    //         break
    //       }
    //       /**
    //        * Undefined Error
    //        */
    //       default: {
    //         this.openNotify('error', 'common_notificationUndefinedError')
    //       }
    //     }
    //
    //     return response
    //   })
    // },

    payPaymentHandle(id, {goToTable = false, create = false}){

      let text = {
        title: 'common_AreYouSure',
        txt: '',
        yes: 'common_confirm',
        no: 'common_confirmNo'
      }

      let confirm = () => {

        this.payPaymentPayoneer(id, {goToTable: goToTable, create: create})

        this.$store.commit('setConfirmDeletePopup', false)
        return true
      }

      this.deleteFunc(text, confirm)
    },

    async payPaymentPayoneer(id, {goToTable = false, create = false}) {

      let assess = true
      await this.newPaymentPayoneerAccess(id).then(response => {
        assess = response
      })

      if(!assess) return

      return this.$store.dispatch('payNewPaymentsPayoneer', id).then((response) => {
        switch (this.getResponseStatus(response)) {
          /**
           * Success
           */
          case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
            if (create) {
              this.openNotify('success', 'common_notificationRecordCreated')
            } else {
              this.openNotify('success', 'common_notificationRecordChanged')
            }

            if (goToTable) {
              setTimeout(() => {
                this.$router.push(this.$store.getters.GET_PATHS.financeNewPaymentsPayoneer)
              }, 2000)
            } else {
              let item = this._.find(this.$store.getters.getNewPaymentsPayoneer, {id: parseInt(id)})
              if(item){
                item.status = NEW_PAYMENTS_PAYONEER_STATUSES.COMPLETE
              }
            }
            break
          }
          /**
           * Validation Error
           */
          case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
            let errors = response.response.data.errors;
            this.notifyErrorHelper(errors)
            break
          }
          /**
           * Undefined Error
           */
          default: {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
        }

        return response
      })
    },
  }
}
