<template>
  <div class="manager-btn"
       :id="'manager-btn-' + id"
       :class="{bottom : position == 'bottom', center : position == 'center'}"
       ref="manager"
       v-if="slots"
  >
    <div class="manager-btn__list"
         :id="'manager-btn-list-' + id"
         ref="managerList"
         :class="{ more : !showBtnMore || slots <= 1}"
         v-if="isCalculate"
    >
      <slot name="item">

      </slot>
      <div class="manager-btn__btn-more"
           :id="'manager-btn-more-' + id"
           v-show="showBtnMore  && slots > 1"
           :class="{active : showMoreBlock}"
           @mouseover="showMoreBlock = true"
           @mouseleave="showMoreBlock = false"
           ref="managerBtnMore"
      >
        <MoreIconSVG/>

        <div class="manager-btn__more-block"
             v-show="showMoreBlock && slots > 1"
             :id="'manager-btn-more-block-' + id"
             ref="managerMoreBlock"
        >
          <slot name="item">

          </slot>
        </div>
      </div>
    </div>

    <div class="manager-btn__list"
         :id="'manager-btn-list-' + id"
         ref="managerList"
         v-else
    >
      <div class="manager-btn__btn-more"
           :id="'manager-btn-more-' + id"
           :class="{active : showMoreBlock}"
           @mouseover="showMoreBlock = true"
           @mouseleave="showMoreBlock = false"
           ref="managerBtnMore"
      >
        <MoreIconSVG/>

        <div class="manager-btn__more-block"
             v-show="showMoreBlock && slots > 1"
             :id="'manager-btn-more-block-' + id"
             ref="managerMoreBlock"
        >
          <slot name="item">

          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MoreIconSVG from '../../../../../public/img/UI-group/more-icon.svg?inline'


export default {
  name: "ManagerButton",
  components: {
    MoreIconSVG
  },

  props: {
    position: String,
    isCalculate: {
      type: Boolean,
      default: true
    },
  },

  data() {
    return {
      id: null,
      showBtnMore: false,
      showMoreBlock: false,
      items: [],

      slots: this.$slots?.item ? this.$slots.item.length : 0,

      resizeFlag: true,
      managerListCurrentWidth: 0,
    }
  },


  updated() {
    let updatedSlotsLength = this.$slots?.item ? this.$slots.item.length : 0

    if (this.slots !== updatedSlotsLength) {
      // console.log('update', this.slots, this.$slots.item.length)
      this.slots = this.$slots.item.length

      this.reset()
      this.initial()
      this.$emit('reloadManagerBtn')

    }


  },

  mounted() {
    this.id = this._uid;

    this.reset()
    this.initial()

    // setTimeout(() => {
    // let button = document.getElementById("manager-btn-list-" + this.id)
    //
    // if(!button){
    //   return
    // }
    //
    // let listItems = button.childNodes
    //
    // for (let i = 0; i < listItems.length - 1; i++) {
    //   if(listItems[i].length === undefined)
    //     this.items.push(listItems[i].clientWidth + 15);
    // }

    // this.resizeWindowEvent();
    // }, 1);


    window.addEventListener("resize", this.resizeWindowEvent);
  },

  methods: {

    reset() {
      if(!this.isCalculate) return

      this.managerListCurrentWidth = 0
      this.showBtnMore = false
      this.showMoreBlock = false


      if(this.$refs.managerList) {
        let managerList = this.$refs.managerList
        managerList.querySelectorAll(':scope > .link-btn').forEach((element) => {
          element.style.display = 'flex'
        })
      }
    },

    initial() {
      if(!this.isCalculate) return


      if(this.$refs.manager) {
        let manager = this.$refs.manager
        let managerList = this.$refs.managerList
        // let managerBtnMore = this.$refs.managerBtnMore
        let managerMoreBlock = this.$refs.managerMoreBlock

        let managerWidth = manager.clientWidth
        // let managerListWidth = managerList.clientWidth

        let countVisibleItems = 0
        let stepFlag = true

        // console.log('\n',
        //     'managerWidth: ', managerWidth, '\n',
        //     'managerListWidth: ', managerListWidth, '\n',
        //     'managerList', managerList, '\n',
        //     'managerBtnMore', managerBtnMore, '\n',
        //     'managerMoreBlock', managerMoreBlock, '\n'
        // )

        /**
         * We go through the elements with the .link-btn class,
         * inside the loop we check the width of all elements.
         * If the width of the elements is less than the width of the block,
         * we show them, and hide the rest.
         */

        if (managerList) {
          let itemsProcessed = 0
          let managerListArray = managerList.querySelectorAll(':scope > .link-btn')

          // console.log('managerListArray: ', managerListArray)

          // console.log('====================================================')

          managerListArray.forEach((element) => {
            // console.log(
            //     'element: ', element, '\n',
            //     'elementWidth: ', element.clientWidth, '\n',
            //     'managerWidth: ', managerWidth, '\n',
            //     'this.managerListCurrentWidth: ', managerListCurrentWidth, '\n'
            // )

            if (managerListArray.length === 1) {
              element.style.display = 'flex'
              return
            }
            if ((this.managerListCurrentWidth + element.clientWidth + 20) < managerWidth && stepFlag) {
              this.managerListCurrentWidth = (this.managerListCurrentWidth + element.clientWidth + 16)
              countVisibleItems++
              element.style.display = 'flex'
              // console.log('element show: ', element)
            } else {
              this.showBtnMore = true
              element.style.display = 'none'
              stepFlag = false
              // console.log('element hide: ', element)
            }

            /**
             * At the last step, we go through the hidden elements.
             * Before that, the index and the number of elements that need to be shown have matured.
             * Hide the shown elements.
             */

            itemsProcessed++
            if (itemsProcessed === managerListArray.length) {
              managerMoreBlock.querySelectorAll('.link-btn').forEach((item, index) => {
                // console.log('countVisibleItems and index: ', countVisibleItems, index)
                if (countVisibleItems > index) {
                  item.style.display = 'none'
                } else {
                  item.style.display = 'flex'
                }
              })
            }
          })

          // console.log('====================================================')

        }
      }

      // console.log('countVisibleItems: ', countVisibleItems);

    },


    resizeWindowEvent() {
      // console.log(this.isCalculate);

      if (this.resizeFlag) {
        this.resizeFlag = false

        setTimeout(() => {
          this.reset()
          this.initial()
          this.resizeFlag = true
        }, 1000);
      }

      // setTimeout(() => {
      //
      //   let listWidth = document.getElementById("manager-btn-" + this.id).clientWidth;
      //   let list = document.getElementById("manager-btn-list-" + this.id);
      //   let moreBtnWidth = document.getElementById("manager-btn-more-" + this.id).clientWidth;
      //   let moreBlockList = document.getElementById("manager-btn-more-block-" + this.id);
      //   let listItems = list.childNodes;
      //   let listMoreItems = moreBlockList.childNodes;
      //   let actualWidth = 0;
      //
      //   for (let i = 0; i < listItems.length - 1; i++) {
      //     let child = listItems[i]
      //     if(child.length !== undefined)
      //       list.removeChild(child)
      //   }
      //
      //   for (let i = 0; i < listMoreItems.length - 1; i++) {
      //     let child = listMoreItems[i]
      //     if(child.length !== undefined)
      //       moreBlockList.removeChild(child)
      //   }
      //
      //   for (let i = 0; i < this.items.length; i++) {
      //     actualWidth = actualWidth + this.items[i];
      //
      //     if(this.items[i + 1] === undefined) {
      //
      //       if ((actualWidth) < listWidth) {
      //         if(listItems[i].length === undefined) {
      //           listMoreItems[i].style.display = 'none';
      //           listItems[i].style.display = 'flex';
      //         }
      //       } else {
      //         if(listItems[i].length === undefined) {
      //           listItems[i].style.display = 'none';
      //           listMoreItems[i].style.display = 'flex';
      //         }
      //       }
      //
      //     } else {
      //
      //       if(actualWidth + moreBtnWidth < listWidth){
      //         if(listItems[i].length === undefined){
      //           listMoreItems[i].style.display = 'none';
      //           listItems[i].style.display = 'flex';
      //         }
      //       } else {
      //         if(listItems[i].length === undefined){
      //           listItems[i].style.display = 'none';
      //           listMoreItems[i].style.display = 'flex';
      //         }
      //       }
      //
      //       // if ((actualWidth + this.items[i + 1] - 40) < listWidth) {
      //       //   console.log(222222);
      //       //   console.log(actualWidth + this.items[i + 1] - 40);
      //       //
      //       //   listMoreItems[i].style.display = 'none';
      //       //   listItems[i].style.display = 'flex';
      //       // } else {
      //       //   console.log(222222);
      //       //   console.log(actualWidth + this.items[i + 1] - 40);
      //       //   listItems[i].style.display = 'none';
      //       //   listMoreItems[i].style.display = 'flex';
      //       // }
      //     }
      //   }
      //
      //   if (actualWidth > listWidth){
      //     this.showBtnMore = true;
      //   } else {
      //     this.showBtnMore = false;
      //   }
      // }, 1);
    },

  },

  created() {

  },

  destroyed() {
    window.removeEventListener("resize", this.resizeWindowEvent);
  },
}
</script>

<style lang="scss">
@import "../../../../scss/colors";
@import "../../../../scss/mixins/mixins";


.manager-btn {
  position: relative;
  /*max-width: 230px;*/
  width: 230px;
  /*width: 100%;*/
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;

  /* &.down .manager-btn__more-block{
     bottom: auto;
     top: calc(100% + 9px);
   }*/

  &.alone {
    width: 22px;
  }

  &--shop-admin {
    width: 210px;

    @include for-1800 {
      width: 50px;
    }
  }

  &.w160 {
    width: 160px;
  }

  &.w170 {
    width: 170px;
  }

  &.w210 {
    width: 210px;
  }

  @media(max-width: 1400px) {
    max-width: 150px;
  }

  &__list {
    display: flex;
    /*max-width: 230px;*/

    > .link-btn {
      position: relative;
      margin-right: 15px;
      padding-right: 15px;
      white-space: nowrap;

      &:after {
        content: '';
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        height: 10px;
        background: $silver;
        display: flex;
        position: absolute;
      }

      &:last-child {
        margin-right: 0;
        padding-right: 0;

        &:after {
          display: none;
        }
      }
    }

    &.more > .link-btn {
      &:nth-last-child(2) {
        margin-right: 0;
        padding-right: 0;

        &:after {
          display: none;
        }
      }
    }
  }

  &__btn-more {
    cursor: pointer;
    position: relative;


    svg {
      transition: .3s;
    }

    &.active,
    &:hover {

      > svg {
        filter: brightness(1.47);
      }
    }

    &:before {
      content: '';
      left: -10px;
      width: calc(100% + 20px);
      position: absolute;
      top: -10px;
      height: calc(100% + 20px);
    }
  }

  &__more-block {
    position: absolute;
    bottom: calc(100% + 9px);
    right: 0;
    transform: translateX(30%);
    background: #FFFFFF;
    border: 1px solid $mainBg;
    box-shadow: 0px 4px 7px #ECE5DC;
    max-width: 170px;
    min-width: 170px;
    width: 100%;
    display: flex;
    z-index: 2;
    flex-direction: column;
    padding: 15px;

    overflow: auto;
    max-height: 280px;

    .link-btn {
      margin-bottom: 13px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &.more-180 &__more-block {
    max-width: 180px;
    min-width: 180px;
  }

  &.bottom &__more-block {
    bottom: auto;
    top: 100%;
  }

  &.center &__more-block {
    bottom: auto;
    top: 50%;
    transform: translateY(-50%);
  }

  @include for-1120 {
    &.right &__more-block {
      transform: translateX(25px);
    }
  }

  &.right {
    .table-manage-list {
      justify-content: flex-end;
      display: flex;
    }

    .manager-btn__list {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      padding-right: 15px;
    }
  }

}
</style>
