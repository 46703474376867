<template>
  <NewPaymentsPayoneerTableAdmin
          @filter="filter"
          :filterGetParams="filterGetParams"
          :countFilterParams="countFilterParams"
          :navTabs="navTabs"
          @changeFilter="changeFilter"
          @showMore="showMore"
          @changeTab="changeTab"
          @resetFilter="resetFilter"
          @downloadFiles="downloadFiles"
          @massPay="massPay"
          @reload="reload"
  />
</template>

<script>
  import NewPaymentsPayoneerTableAdmin from "./NewPaymentsPayoneerTableAdmin/NewPaymentsPayoneerTableAdmin";
  import {routeFilter} from "../../../../../mixins/routeFilterMixins/routeFilter";
  import {newPaymentsPayoneerMixin} from "@/mixins/newPaymentsPayoneerMixins/newPaymentsPayoneerMixin";

  export default {
    name: "NewPaymentsPayoneerTable",

    components: {
      NewPaymentsPayoneerTableAdmin,
    },

    mixins: [routeFilter, newPaymentsPayoneerMixin],

    data() {
      return {
        forPage: this.$store.getters.getNewPaymentsPayoneerForPage,
        page: 1,
        onePage: 1,
        filterDate: [],
        filterOrder: '',
        filterUserId: '',
        filterUserName: '',
        filterTotal: '',
        filterComment: '',
        filterTransaction: '',
        filterStatus: 'success,on_hold',

        filterGetParams: {},
        countFilterParams: 0,

        navTabs: {
          payments: {
            label: this.$t(`payments_payments.localization_value.value`),
            name: 'payments',
            id: 1,
            active: true
          },
          requests: {
            label: this.$t(`payments_requests.localization_value.value`),
            name: 'requests',
            id: 2,
            active: false,
          },
        },

      }
    },

    mounted() {
      if(this.$route.query.type) {
        this.skipRadioChecked(this.navTabs, 'active')
        this.navTabs[this.$route.query.type].active = true
      }
      this.filter()
    },

    methods: {
      getFilterParam() {
        this.filterGetParams = {
          page: this.$route.query.page,
          count: this.$route.query.count,
          date: this.$route.query.date,
          order: this.$route.query.order,
          userName: this.$route.query.userName,
          userId: this.$route.query.userId,
          total: this.$route.query.total,
          transaction: this.$route.query.transaction,
          comment: this.$route.query.comment,
        }
      },

      filter(next = false) {
        this.getFilterParam()

        let forPage, page, url

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(next, this)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        // generate other filter if has get params in URL
        this.generateParamsFilterFromUrl(this)


        // Generate filter URL
        url = this.generateFilterUrl(page, forPage)

        let typeDispatch = 'fetchNewPaymentsPayoneer'

        if(this.navTabs.requests.active) {
          typeDispatch = 'fetchPayoneerRequest'
        }

        // True if get next page
        if (next) this.$store.commit('setNextNewPaymentsPayoneerPage', true)

        // save filter params in store
        this.$store.commit('setNewPaymentsPayoneerFilter', window.location.search)
        this.$store.dispatch(typeDispatch, url).then(() => {
          // skip get next page
          this.$store.commit('setNextNewPaymentsPayoneerPage', false)

          if(this.$route.query.payId){
            console.log(444444444);
            this.payPaymentPayoneer(this.$route.query.payId, {goToTable: false}).then(() => {
              let query = Object.assign({}, this.$route.query);
              delete query.payId;
              this.$router.replace({ query });
            })
          }
        })

        this.checkCountFilter(['userName'])
      },

      generateFilterUrl(page, forPage, exportIds = false) {
        let myQuery = this.createQueryBuilderObject({withUserNewMark: true})

        this.generateFilterQueryParams(
          myQuery,
          {
            filterOrder: 'id',
            // filterTotal: 'PaymentTransactionPaymentTotal',
            filterUserId: 'user_id',
            filterComment: 'comment',
            filterTransaction: 'transaction_id',
            // filterStatus: 'PaymentTransactionStatus',
          },
        )

        this.filterDate = this.$route.query.date !== undefined ? this.$route.query.date : ''
        if (this.filterDate.length > 0) {
          let date = this.generateDateForFilter(this.filterDate, 'YYYY-MM-DD')

          myQuery.whereIn(
            'between_created_at',
            date
          )
        }

        // If generate on export file
        if(exportIds && exportIds.length > 0) {
          myQuery.whereIn('PaymentId', exportIds)
        }

        return this.generateGetParamsFromQuery(myQuery, forPage, page)


      },

      massPay() {
        let exportIds

        exportIds = this.getCheckedRows('row-name')
        if(exportIds.length > 0){
          exportIds.map(item => {
            this.payPayment(item, {goToTable: false})
          })
        }

      },

      downloadFiles(type) {
        let forPage, page, url, exportIds

        exportIds = this.getCheckedRows('row-name')

        // generate main filter if has get params in URL
        let generateMainFilter = this.generateMainFilterFromUrl(false, this)

        page = generateMainFilter.page
        forPage = generateMainFilter.forPage

        url = this.generateFilterUrl(page, forPage, exportIds)

        let typeDispatch = 'getExportNewPaymentsPayoneer'

        if(this.navTabs.requests.active) {
          typeDispatch = 'getExportCheckPayoneer'
        }

        this.$store.dispatch(typeDispatch, {filter: url, type: type}).then((response) => {
          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

            this.createDownloadLink({data: response.data, name: `${this.navTabs.requests.active ? 'Requests payments' : 'New Payments'}.${type}`, type: type})
          } else {
            setTimeout(() => {
              this.openNotify('error', 'common_notificationUndefinedError')
            }, 200)
          }
        })

      },

    },

  }
</script>

<style scoped>

</style>
