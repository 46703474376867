var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fragment"},[_c('div',{staticClass:"content-top-line content-top-line--with-bottom-line",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('span',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
                  'payments_payments',
                  'payments_requests',
                  ])}}}),_c('div',{staticClass:"content-tabs content-tabs--separator"},[_vm._l((_vm.navTabs),function(item,index){return [_c('div',{key:index,staticClass:"content-tabs__item",class:{
                  active: item.active,
                  'admin-edit-item': _vm.isAdmin || _vm.checkImpersonate()
               },on:{"click":function($event){return _vm.$emit('changeTab', item.name)}}},[_c('div',{staticClass:"content-tabs__ico"},[_vm._v(" "+_vm._s(item.label)+" ")])])]})],2),_c('div',{staticClass:"content-top-line-wrap"},[_c('ToggleFilterButton',{attrs:{"count":_vm.countFilterParams,"showFilter":_vm.showFilter},on:{"toggleFilter":function($event){return _vm.$emit('toggleFilter')}}})],1)]),_c('div',{staticClass:"light-bg-block"},[_c('div',{staticClass:"btn-right-block",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
          'common_AddNew',
        ])}}}),(_vm.isAdmin)?_c('router-link',{attrs:{"to":_vm.$store.getters.GET_PATHS.financeNewPaymentsPayoneerCreate}},[_c('MainButton',{staticClass:"btn-fit-content ml-3 white-space-nowrap",attrs:{"value":_vm.$t('common_AddNew.localization_value.value'),"ico":'ico'}},[_c('template',{slot:"ico"},[_c('PlusIconSVG',{staticClass:"btn-icon-plus"})],1)],2)],1):_vm._e()],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }