
<template>
  <modal
      @close="$emit('close')"
      class="custom-popup-modal custom-popup send-email-popup"
  >
    <template slot="header">
      <div
          v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['userNotes_SendEmailTo'])"></div>
        {{$t('userNotes_SendEmailTo.localization_value.value')}} {{userName}}
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['userNotes_Message'])"></div>
        <TextareaDefault
          class="mb-4 mh-225"
          :label="$t('userNotes_Message.localization_value.value')"
          v-model="userNote.data.text"
        />
      </div>
    </template>
    <template slot="footer">
      <div class="custom-popup__btn align-items-center d-flex flex-wrap"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_cancel','common_save','userNotes_SendToClient'])"></div>
        <span
            class="site-link site-link--alt custom-popup__btn-i mr-4 mt-3 mb-3"
            @click="$emit('close')"
        >
          {{$t('common_cancel.localization_value.value')}}
        </span>
        <span
            class="site-link site-link--alt custom-popup__btn-i mr-4 mt-3 mb-3"
            @click="saveNote()"
        >
          {{$t('common_save.localization_value.value')}}
        </span>

        <MainButton
            class="custom-popup__btn-i "
            :value="$t('userNotes_SendToClient.localization_value.value')"
            @click.native="saveNote(true)"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from "../../../../../commonModals/Modal";
  import MainButton from "../../../../../UI/buttons/MainButton/MainButton";
  import TextareaDefault from "../../../../../UI/textarea/TextareaDefault/TextareaDefault";
  import {UserNote} from "../../../../../coreComponents/TableComponents/TableUserColumn/models/UserNote";


  export default {
    name: "SendEmailPopup",
    components: {
      Modal,
      MainButton,
      TextareaDefault,
    },
    props: {
      userId: Number,
      userName: String
    },

    data() {
      return {
        userNote: new UserNote(),
      }
    },

    mounted() {
      this.userNote.setUserId(this.userId)
    },

    methods: {
      saveNote(sendEmail = false) {
        this.userNote.setSendEmail(sendEmail)
        if(!this.userNote.validationItem()) return

        let data = this.userNote.prepareSave()

        this.$store.dispatch('createUserNotes', data).then(response => {
          if (!this.getResponseStatus(response)) return this.openNotify('error', 'common_notificationUndefinedError')

          switch (this.getResponseStatus(response)) {
            /**
             * Success
             */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.$emit('close')
              this.openNotify('success', 'common_notificationRecordCreated')
              break
            }
            /**
             * Validation Error
             */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
            /**
             * Undefined Error
             */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      }
    }
  }
</script>

<style lang="scss">
  .send-email-popup .modal-component__inner{
    max-width: 518px;
  }

  .mh-225 {
    max-width: 390px;

    textarea{
      min-height: 225px;
      padding-right: 15px;
    }
  }
</style>
