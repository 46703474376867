<template>
  <div class="content-top-line-btn">
    <div class="content-filters">
      <div class="content-filters__btn btn-style mobile-filter-btn" v-bind:class="{show : showFilter}" @click="$emit('toggleFilter')">
        {{ $t('common_filtersApplied.localization_value.value') }} ({{count}})
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ToggleFilterButton",

    props: ['showFilter', 'count']
  }
</script>

<style lang="scss">
  @import "../../../../scss/colors";
  @import "../../../../scss/mixins/mixins";

</style>
