<template>
  <div class="fragment">

<!--    <SearchEngine/>-->


    <div class="content-top-line content-top-line--with-bottom-line"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit"
              @click="editTranslate([
                  'payments_payments',
                  'payments_requests',
                  ])"></span>
      <div class="content-tabs content-tabs--separator">
        <template v-for="(item, index) in navTabs">
          <div class="content-tabs__item"
               :key="index"
               v-bind:class="{
                  active: item.active,
                  'admin-edit-item': isAdmin || checkImpersonate()
               }"
               @click="$emit('changeTab', item.name)">
<!--            <div v-if="index !== navTabs.length" class="admin-edit" @click="editPopup(item.itemType)"></div>-->
            <div class="content-tabs__ico">
              {{item.label}}
            </div>
          </div>
        </template>
      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton :count="countFilterParams" @toggleFilter="$emit('toggleFilter')" :showFilter="showFilter" />
      </div>
    </div>

    <div class="light-bg-block">
<!--      <div class="btn-left-block">-->
<!--        <div class="btn-left-block-i"-->
<!--             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--        <div class="admin-edit" @click="editTranslate([-->
<!--          'payments_PayFromAccount',-->
<!--        ])"></div>-->
<!--          <LinkButton-->
<!--              :value="$t('payments_PayFromAccount.localization_value.value')"-->
<!--              :type="'pay-acc-bal'"-->
<!--              @click.native="payPaymentHandle"-->
<!--          />-->
<!--        </div>-->
<!--      </div>-->
      <div class="btn-right-block"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
          'common_AddNew',
        ])"></div>
        <router-link v-if="isAdmin"
            :to="$store.getters.GET_PATHS.financeNewPaymentsPayoneerCreate">
          <MainButton class="btn-fit-content ml-3 white-space-nowrap"
                      :value="$t('common_AddNew.localization_value.value')"
                      :ico="'ico'"
          >
            <template slot="ico">
              <PlusIconSVG class="btn-icon-plus"/>
            </template>
          </MainButton>
        </router-link>
      </div>
    </div>



  </div>
</template>



<script>
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import PlusIconSVG from '../../../../../../../../public/img/UI-group/plus-icon.svg?inline'
  // import SearchEngine from "../../../../../../coreComponents/SearchEngine/SearchEngine";
  // import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
  // import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";


  export default {
    name: "NewPaymentsPayoneerHead",
    components: {
      ToggleFilterButton,
      MainButton,
      PlusIconSVG,
      // DefaultCheckbox,
      // SearchEngine,
      // LinkButton,
    },

    props: {
      countFilterParams: Number,
      showFilter: Boolean,
      navTabs: Object,
    },

    watch: {
      activeTab(newVal) {
        this.tab = newVal
      }
    },

    data(){
      return{
        tab: this.activeTab,
      }
    },

    methods: {

      changeTab(id) {
        this.$emit('changeTab', id)
      },

      changeDate() {
        console.log('changeDate function');
      },

      openHTMLCodePopup(){
        this.changeGenPayPalBtnPopup(false)
        this.changeCodeHTMLPopup(true)
      },

      payPaymentHandle(){

        let text = {
          title: 'common_AreYouSure',
          txt: '',
          yes: 'common_confirm',
          no: 'common_confirmNo'
        }

        let confirm = () => {

          this.$emit('massPay')

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },

    }
  }
</script>

<style scoped lang="scss">
  @import "../../../../../../../scss/mixins/mixins";


</style>

