import {checkValid} from "../../../globalModels/functions/ModelsValidate";
import {NEW_PAYMENTS_PAYONEER_STATUSES} from "../../../../staticData/staticVariables";


export function NewPaymentsPayoneer() {

  this.checkValid = checkValid

  this.newPayments = {
    id: -1,
    user: {},
    userId: '',
    amount: 0,
    comment: '',
    status: '',
    email: '',
    transaction: '',
    historyOfChanged: null,
  }

  this.validation = {
    user: false,
    amount: false,
    comment: false,
    email: false,
    transaction: false,
  }

  this.validationTranslate = {
    user: '',
    amount: '',
    comment: '',
    email: '',
    transaction: '',
  }

  this.validationTxt = {
    user: false,
    amount: false,
    comment: false,
    email: false,
    transaction: false,
  }

}


/*****************   SETTERS   ******************/

NewPaymentsPayoneer.prototype.setUser = function(val){
  this.newPayments.user = val;
  this.newPayments.userId = val.id;
}

NewPaymentsPayoneer.prototype.setAmount = function(val){
  this.newPayments.amount = val;
}

NewPaymentsPayoneer.prototype.setComment = function(val){
  this.newPayments.comment = val;
}

NewPaymentsPayoneer.prototype.setHistoryOfChanged = function(val){
  this.newPayments.historyOfChanged = val;
}

NewPaymentsPayoneer.prototype.setNewPaymentPayoneerItem = function(val){
  this.newPayments.id = val.id;
  this.newPayments.user = val.user.id ? val.user : null;
  this.newPayments.userId = val.user.id;
  this.newPayments.amount = val.amount;
  this.newPayments.comment = val.comment || '';
  this.newPayments.status = val.status;
  this.newPayments.transaction = val.transaction_id;
  // this.newPayments.historyOfChanged = val.activityUUID;
}

/*****************   GETTERS   ******************/
NewPaymentsPayoneer.prototype.getUser = function(){
  return this.newPayments.user;
}

NewPaymentsPayoneer.prototype.getAmount = function(){
  return this.newPayments.amount;
}

NewPaymentsPayoneer.prototype.getComment = function(){
  return this.newPayments.comment;
}

NewPaymentsPayoneer.prototype.getHistoryOfChanged = function(){
  return this.newPayments.historyOfChanged;
}


/*****************   VALIDATION   ******************/

NewPaymentsPayoneer.prototype.newPaymentsPayoneerValidate = function(){

  let validationItems = {
    user: this.newPayments.userId,
    amount: this.newPayments.amount,
  }

  let validationOptions = {
    user: {type: ['empty']},
    amount: {type: ['numeric', 'empty']},
  }

  return this.checkValid(validationItems, validationOptions)
}

NewPaymentsPayoneer.prototype.createCheckPayoneerValidate = function(){

  let validationItems = {
    amount: this.newPayments.amount,
    // email: this.newPayments.email,
    transaction: this.newPayments.transaction,
  }

  let validationOptions = {
    amount: {type: ['numeric', 'not-zero', 'empty']},
    // email: {type: ['email', 'empty']},
    transaction: {type: ['empty']},
  }

  return this.checkValid(validationItems, validationOptions)
}


/****************    PREPARE    ********************/

NewPaymentsPayoneer.prototype.newPaymentsPayoneerPrepare = function(){
  let data = {
    "comment" : this.newPayments.comment,
    "amount": this.newPayments.amount,
    "user_id": this.newPayments.userId,
    "transaction_id": this.newPayments.transaction,
  }
  if (this.newPayments.status !== NEW_PAYMENTS_PAYONEER_STATUSES.COMPLETE) {
    // data["deferred"] = deferred
    data["amount"] = this.newPayments.amount
    data["user_id"] = this.newPayments.userId
  }
  return data
}

NewPaymentsPayoneer.prototype.createCheckPayoneerPrepare = function(){
  return {
    "comment" : this.newPayments.comment,
    "amount": this.newPayments.amount,
    // "payoneer_from_email": this.newPayments.email,
    "transaction_id": this.newPayments.transaction,
  }
}




