var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"breadcrumbs"},[_c('div',{staticClass:"breadcrumbs__inner"},[_c('div',{staticClass:"breadcrumbs__title",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
        _vm.$route.meta.pageTitle,
      ])}}}),_vm._v(" "+_vm._s(_vm.$t(_vm.$route.meta.pageTitle + '.localization_value.value'))+" ")]),(_vm.hideBreadcrumbs !== true)?_c('div',{staticClass:"breadcrumbs__items"},[_c('div',{staticClass:"breadcrumbs__item",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
        'menu_dashboard',
      ])}}}),_c('router-link',{staticClass:"breadcrumbs__link",attrs:{"to":_vm.$store.getters.GET_PATHS.dashboard}},[_vm._v(_vm._s(_vm.$t('menu_dashboard.localization_value.value')))])],1),_vm._l((_vm.$route.meta.breadcrumbs.key),function(item,index){return _c('div',{key:index,staticClass:"breadcrumbs__item",class:{'admin-edit-item': _vm.$store.getters.getUserProfile.isAdmin || _vm.checkImpersonate()}},[_c('div',{staticClass:"admin-edit",on:{"click":function($event){return _vm.editTranslate([
          _vm.$route.meta.breadcrumbs.items[item].name,
        ])}}}),_c('router-link',{staticClass:"breadcrumbs__link",attrs:{"to":_vm.$route.meta.breadcrumbs.items[item].link}},[_vm._v(" "+_vm._s(_vm.$t(_vm.$route.meta.breadcrumbs.items[item].name + '.localization_value.value'))+" ")])],1)})],2):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }