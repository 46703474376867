<template>
  <div class="sum-input"
       :class="{
        'sum-input--dollar' : icoType == 'dollar',
        'sum-input--euro' : icoType == 'euro',
        'sum-input--cm' : icoType == 'cm',
        'sum-input--inc' : icoType == 'inc',
        'disabled' : disabled === true
      }"
  >
    <label class="sum-input__label">
      <span :class="{'white-space-pre-wrap' : preWrap}">{{ label }}</span>

      <input type="text"
             class="sum-input__input"
             :value="sumValue"
             :id="'default-input-' + id"
             :name="`name-` + id"
             v-money="money"
             :placeholder="placeholder"
             :disabled="disabled == true"
             @change="sumInputChange"
             @input="sumInput"
             @blur="focusOut"
      />
    </label>

    <span class="sum-input__caption" v-if="caption">{{ caption }}</span>
    <span class="textarea-default__error error-field" v-if="error && errorTxt">{{ errorTxt }}</span>
  </div>
</template>

<script>


export default {
  name: "InputSum",

  props: {
    value: [String, Number],
    icoType: {
      type: String,
      default: 'dollar'
    },
    label: String,
    placeholder: String,
    disabled: Boolean,
    preWrap: Boolean,
    caption: String,
    error: [Boolean, String],
    errorTxt: String,
    max: {
      type: Number,
      default: -1
    }
  },

  data() {
    return {
      sumValue: this.value,
      id: null,

      money: {
        decimal: '.',
        thousands: ',',
        precision: 2
      }
    }
  },

  watch: {
    value(newVal) {
      this.sumValue = newVal
    }
  },

  mounted () {
    this.id = this._uid
  },

  methods: {

    focusOut(val) {
      this.$emit('focusOut', val.target.value.replaceAll(/,/ig, ''))
    },

    sumInputChange(val) {
      /**
       * FIX plugin problem - looping update
       */
      if (parseFloat(val.target.value) === parseFloat(this.sumValue)) return
      this.$emit('change', val.target.value.replaceAll(/,/ig, ''))
    },

    sumInput(val) {
      let value = val.target.value.replaceAll(/,/ig, '')

      if (this.max > -1 && parseFloat(value) > this.max)
        value = this.max * 100

      // this.$emit('input', val.target.value.replaceAll(/,/ig, ''))
      this.$emit('input', value)
    },
  }
}
</script>

<style lang="scss">
@import "../../../../scss/colors";
@import "../../../../scss/mixins/mixins";

.sum-input {
  position: relative;
  border: 1px solid $borderBrown;
  border-radius: 5px;
  height: 42px;

  &.ui-no-valid {
    border: 2px solid #F2994A;
  }

  &.ui-no-valid.error-down {
    margin-bottom: 16px;
  }

  @include for-680 {
    margin-top: 15px;
  }

  &.label-wrap &__label span {
    white-space: normal;
  }

  &__label {

    span {
      position: absolute;
      font-size: 10px;
      line-height: 12px;
      left: 0;
      bottom: calc(100% + 4px);
      color: $borderBrown;
      white-space: nowrap;
    }

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 30px;
      z-index: 2;
      height: 100%;
      background-color: white;
      background-position: center;
      background-repeat: no-repeat;
      background-size: auto;
      border-radius: 5px 0 0 5px;
    }
  }


  &--dollar &__label:before {
    background-image: url("../../../../assets/img/UI-group/label-dollar.svg");
  }

  &--euro &__label:before {
    background-image: url("../../../../assets/img/UI-group/euro.svg");
    background-size: 13px;
  }

  &--cm &__label:before {
    content: 'cm';
    padding-top: 13px;
    padding-left: 7px;
    font-size: 13px;
    color: #BBAD9C;
  }

  &--inc &__label:before {
    content: 'inc';
    padding-top: 13px;
    padding-left: 7px;
    font-size: 13px;
    color: #BBAD9C;
  }

  &__input {
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 45px;
    padding-right: 10px;
    border: 0;
    width: 100%;
    background: $mainBg;
    z-index: 1;
    position: relative;
    border-radius: 5px 5px;

    &:disabled {
      background: $brownOpacity02;
      color: #000000;
    }


    &::placeholder {
      color: $blackOpacity02;
    }
  }


  &.disabled {
    opacity: 0.4;
  }

  /*&.disabled-opacity{*/
  /*opacity: 0.4;*/
  /*}*/

  &__caption {
    width: 100%;
    text-align: right;
    font-size: 11px;
    line-height: 13px;
    color: $borderBrown;
    display: flex;
    justify-content: flex-end;
    margin-top: 15px;
    margin-bottom: -10px;
  }

  .error-field {
    top: auto;
    bottom: -16px;
  }

}

.white-space-pre-wrap {
  white-space: pre-wrap !important;
}

</style>
