<template>
  <div class="fragment">

    <template v-if="$store.getters.getNewPaymentsPayoneer.length > 0 && $store.getters.getNewPaymentsPayoneerLoading !== true">
      <div class="site-table-wrap small-table"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate([
            'payments_ID',
            'payments_Created',
            'common_user',
            'payments_comment',
            'payments_Total',
            'payments_Status',
            'importO_transactionNumber',
            ])"></div>

        <table class="site-table" v-if="!isMobileFunc()">
          <thead>
          <tr>
<!--            <th></th>-->
            <th> {{$t('payments_ID.localization_value.value')}}</th>
            <th> {{$t('payments_Created.localization_value.value')}}</th>
            <th v-if="isAdmin"> {{$t('common_user.localization_value.value')}}</th>
            <th> {{$t('payments_comment.localization_value.value')}}</th>
            <th v-if="isAdmin"> {{$t('payments_type.localization_value.value')}}</th>
            <th> {{$t('payments_Total.localization_value.value')}},$</th>
            <th v-if="navTabs.payments.active"> {{$t('payments_Status.localization_value.value')}}</th>
            <th> {{$t('importO_transactionNumber.localization_value.value')}}</th>
            <th v-if="navTabs.requests.active"> {{$t('common_email.localization_value.value')}}</th>
            <th width="100%"></th>
            <th></th>
          </tr>
          </thead>
          <tbody>

          <tr v-for="(item, index) in $store.getters.getNewPaymentsPayoneer" :key="index">
<!--            <td>-->
<!--              <DefaultCheckbox-->
<!--                      class="empty-label"-->
<!--                      :dataValue="item.id"-->
<!--                      v-if="item.status !== PAYMENTS_STATUSES.SUCCESS_TRANSACTION_STATUS"-->
<!--              />-->
<!--            </td>-->
            <td>
              <template v-if="navTabs.requests.active">
                #{{item.id}}
              </template>
              <span v-else class="table-link btn-style"
                    @click="showInfoPopup(item.id)"
              >
                #{{item.id}}
              </span>
            </td>
            <td>
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </td>
            <td v-if="isAdmin">
              <TableUserColumn
                  v-if="item.user_id"
                  :item="item"
              />
              <template v-else>—</template>
            </td>
            <td>
              <div class="table-row">
                <TooltipTextHelper
                        :text="item.comment"
                        :paragraph="item.comment"
                        :fullTextWidth="290"
                />
              </div>
            </td>
            <td v-if="isAdmin">
              {{item.create_type}}
            </td>
            <td>
              {{item.amount}}
            </td>
            <td v-if="navTabs.payments.active">
              <div
                      v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
            'common_InProgress',
            'common_Paid',
            'common_Rejected',
            'common_statusNew',
            ])"></div>
                <div class="table-row"
                     v-if="item.status === NEW_PAYMENTS_PAYONEER_STATUSES.NEW">
                  <StatusIcoBtn :type="'star'" class="mr-2 t-0"/>
                  {{$t('common_statusNew.localization_value.value')}}
                </div>
                <div class="table-row"
                     v-if="item.status === NEW_PAYMENTS_PAYONEER_STATUSES.COMPLETE">
                  <StatusIcoBtn :type="'active'" class="mr-2 t-0"/>
                  {{$t('common_Paid.localization_value.value')}}
                </div>
              </div>
            </td>
            <td>
              {{item.transaction_id}}
            </td>
            <td v-if="navTabs.requests.active">
              {{item.payoneer_from_email}}
            </td>
            <td width="100%">

            </td>
            <td align="right">
              <div
                  v-if="isAdmin"
                      v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
            'common_Pay',
            'common_edit',
            'common_delete',
            ])"></div>
                <div class="table-right table-manage-list table-manage-list--small"
                     >

                  <ManagerButton @reloadManagerBtn="$store.commit('setGlobalUpdateManagerButton', Math.random()) " :key="$store.getters.getGlobalUpdateManagerButton"
                          class="right"
                  >
                    <template v-if="item.status !== NEW_PAYMENTS_PAYONEER_STATUSES.COMPLETE" slot="item">
                      <LinkButton
                              :value="$t('common_Pay.localization_value.value')"
                              :type="'dollar'"
                              @click.native="payPaymentHandle(item.id, {goToTable: false})"
                      />
                    </template>
                    <template slot="item" v-if="navTabs.payments.active || isAdmin">
                      <LinkButton
                              :value="$t('common_edit.localization_value.value')"
                              :type="'edit'"
                              :link="$store.getters.GET_PATHS.financeNewPaymentsPayoneer + '/edit/' + item.id + `${navTabs.requests.active ? '?request=true' : ''}`"
                      />
                    </template>
                    <template v-if="item.status !== NEW_PAYMENTS_PAYONEER_STATUSES.COMPLETE" slot="item">
                      <LinkButton
                              :value="$t('common_delete.localization_value.value')"
                              :type="'delete'"
                              @click.native="removePayment(item.id, index)"
                      />
                    </template>
                  </ManagerButton>
                </div>
              </div>
            </td>
          </tr>

          </tbody>
        </table>

        <div class="table-card" v-if="isMobileFunc()">
          <div class="table-card__list">
            <div class="table-card__item"
                 v-for="(item, index) in $store.getters.getNewPaymentsPayoneer"
                 :key="index"
            >
              <NewPaymentsPayoneerTableMobile
                      :item="item"
                      :navTabs="navTabs"
                      @removePayment="removePayment(item.id, index)"
                      @payPayment="payPaymentHandle(item.id,{goToTable: false})"
                      @showInfoPopup="showInfoPopup(item.id)"
              />
            </div>
          </div>
        </div>

        <div class="table-bottom-btn" >
          <div class="table-bottom-btn__inner">
            <ShowMore
                    v-if="$store.getters.getNewPaymentsPayoneerCommonList.next_page_url !== null && $store.getters.getNewPaymentsPayoneer.length > 0"
                    @click.native="$emit('showMore')"
                    v-bind:class="{'disabled-btn' : $store.getters.getNextNewPaymentsPayoneerPage}"
                    :count="$store.getters.getNewPaymentsPayoneerCommonList.total - $store.getters.getNewPaymentsPayoneerForPage * $store.getters.getNewPaymentsPayoneerCommonList.current_page < $store.getters.getNewPaymentsPayoneerForPage ?
                  $store.getters.getNewPaymentsPayoneerCommonList.total - $store.getters.getNewPaymentsPayoneerForPage * $store.getters.getNewPaymentsPayoneerCommonList.current_page:
                  $store.getters.getNewPaymentsPayoneerForPage"
            />

            <ExportBtn
                @downloadFiles="type => $emit('downloadFiles', type)"
                    class="table-bottom-btn__right"/>
          </div>
        </div>
      </div>
    </template>

    <template v-if="$store.getters.getNewPaymentsPayoneerLoading === false && $store.getters.getNewPaymentsPayoneer.length === 0">
      <NoResult
              :countFilterParams="countFilterParams"
              :title="$t('common_noDataYet.localization_value.value')"
              :titleWithFilter="$t('common_noResult.localization_value.value')"
              :text="$t('common_noDataYetTxt.localization_value.value')"
              :textWithFilter="$t('common_noResultSeems.localization_value.value')"
              @resetFilter="$emit('resetFilter')"
      />
    </template>

    <!--<ProductsToWarehouse @close="closePopup" v-if="isModalProductsToWarehouse"/>-->

    <InfoIDPopup
            v-if="isModalInfoIDPopup"
            @close="isModalInfoIDPopup = false"
            @reload="$emit('reload')"
    />

  </div>
</template>

<script>
  import ShowMore from "../../../../../../coreComponents/Buttons/ShowMore/ShowMore";
  import LinkButton from "../../../../../../UI/buttons/LinkButton/LinkButton";
  import ManagerButton from "../../../../../../UI/buttons/ManagerButton/ManagerButton";
  import ExportBtn from '../../../../../../coreComponents/Buttons/Export/Export'
  import {mixinDetictingMobile} from '../../../../../../../mixins/mobileFunctions'
  // import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import StatusIcoBtn from "../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import NoResult from "../../../../../../coreComponents/NoResult/NoResult";
  import {
    // CUSTOMERS_PAYMENTS_STATUSES,
    NEW_PAYMENTS_PAYONEER_STATUSES
  } from "../../../../../../../staticData/staticVariables";
  import NewPaymentsPayoneerTableMobile from "./NewPaymentsPayoneerTableMobile/NewPaymentsPayoneerTableMobile";
  import InfoIDPopup from "../../../../popups/InfoIDPopup/InfoIDPopup";
  import TooltipTextHelper from "../../../../../../coreComponents/TooltipTextHelper/TooltipTextHelper";
  import TableUserColumn from "../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import {newPaymentsPayoneerMixin} from "@/mixins/newPaymentsPayoneerMixins/newPaymentsPayoneerMixin";


  export default {
    name: "NewPaymentsPayoneerTable",

    components: {
      TableUserColumn,
      TooltipTextHelper,
      InfoIDPopup,
      NewPaymentsPayoneerTableMobile,
      NoResult,
      ShowMore,
      ExportBtn,
      LinkButton,
      ManagerButton,
      // DefaultCheckbox,
      StatusIcoBtn,
    },

    mixins: [mixinDetictingMobile, newPaymentsPayoneerMixin],

    props: {
      countFilterParams: Number,
      navTabs: Object,
    },

    data() {
      return {
        orderId: '',
        showFilter: false,
        isMobile: false,

        showContent: false,

        isModalInfoIDPopup: false,
        // PAYMENTS_STATUSES: CUSTOMERS_PAYMENTS_STATUSES,
        NEW_PAYMENTS_PAYONEER_STATUSES: NEW_PAYMENTS_PAYONEER_STATUSES,

        show1: false,
        show2: false,

      }
    },

    methods: {
      closePopup(){
        // this.isModalProductsToWarehouse = false
      },

      showInfoPopup(id) {
        this.$store.dispatch('getNewPaymentsPayoneer', id).then(() => {
          this.isModalInfoIDPopup = true
        })
      },

      removePayment(id, index){
        let text = {
          title: 'common_deleteConfirm',
          txt: '',
          yes: 'common_confirm',
          no: 'common_confirmNo'
        }

        let confirm = () => {

          let typeDispatch = 'deleteNewPaymentsPayoneer'
          if(this.navTabs.requests.active) {
            typeDispatch = 'deleteCheckPayoneer'
          }

          this.$store.dispatch(typeDispatch, id).then((response) => {
            if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {

              let payments = this.$store.getters.getNewPaymentsPayoneer
              payments.splice(index, 1)
              this.openNotify('success', 'common_notificationRecordDeleted')
              this.$emit('reload')

            } else {
              setTimeout(() => {
                this.openNotify('error', 'common_notificationUndefinedError')
              }, 200)
            }
          })

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },

      payPaymentHandle(id, {goToTable=false}){

        let text = {
          title: 'common_AreYouSure',
          txt: '',
          yes: 'common_confirm',
          no: 'common_confirmNo'
        }

        let confirm = () => {

          this.payPaymentPayoneer(id, {goToTable: goToTable})

          this.$store.commit('setConfirmDeletePopup', false)
          return true
        }

        this.deleteFunc(text, confirm)
      },
    }

  }

</script>

<style scoped lang="scss">
  /deep/ .payment-comment .tooltip-helper-full{
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 290px;
    position: relative;
    display: inline-block;
    white-space: nowrap;
  }

  .payment-comment{
    width: 300px;
  }

  .table-manage-list {
    width: 230px;

    .manager-btn {
      max-width: 230px;
    }
  }


  @media (max-width: 1900px){
    .comment-row{
      width: 200px;
    }
  }

  @media (max-width: 1400px){
    .comment-row{
      max-width: 150px;
    }

    .payment-comment,
    /deep/ .payment-comment .tooltip-helper-full{
      width: 150px;
    }
  }

  .site-table thead th:first-child,
  .site-table tbody td:first-child{
    padding-right: 0;
  }

  .site-table thead th:last-child,
  .site-table tbody td:last-child{
    padding-right: 0;
  }

  .small-table th,
  .small-table td{
    white-space: nowrap;
  }

</style>
