<template>
  <modal
      @close="$emit('close')"
      class="custom-popup-modal custom-popup lastFiveIp-popup"
  >
    <template slot="header">
      <div v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['userNotes_LastFiveIP','userNotes_For'])"></div>
        {{$t('userNotes_LastFiveIP.localization_value.value')}} {{$t('userNotes_For.localization_value.value')}} {{ userName }}
      </div>
    </template>
    <template slot="body">
      <div class="custom-popup__content">
        <div class="site-table-wrap table-small">
          <table class="site-table min-w-initial">
            <thead>
            <tr>
              <th v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_date'])"></div>
                {{$t('common_date.localization_value.value')}}
              </th>
              <th v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_ip'])"></div>
                {{$t('common_ip.localization_value.value')}}
              </th>
              <th v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_city'])"></div>
                {{$t('common_city.localization_value.value')}}
              </th>
              <th v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_region'])"></div>
                {{$t('common_region.localization_value.value')}}
              </th>
              <th v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate(['common_country'])"></div>
                {{$t('common_country.localization_value.value')}}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr
                v-for="(item, index) in items"
                :key="index"
            >
              <td>{{ item.created_at }}</td>
              <td>
                <v-popover
                    class="site-tooltip"
                    offset="5"
                    placement="top"
                    trigger="hover"
                >
                  <div class="ellipsis">
                    {{ item.ip }}
                  </div>
                  <template slot="popover">
                    <p>{{ item.ip }}</p>
                  </template>
                </v-popover>
              </td>
              <td>{{ item.city }}</td>
              <td>{{ item.region }}</td>
              <td>{{ item.country }}</td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from "../../../../../commonModals/Modal";

  export default {
    name: "LastFiveIPPopup",
    components: {
      Modal,

    },

    props: {
      userId: Number,
      userName: String
    },

    data() {
      return {
        items: []
      }
    },

    mounted() {
      this.$store.dispatch('getUserLastFiveIPs', this.userId).then(response => {
        this.items = this.getRespData(response)
      })
    },

    methods: {}
  }
</script>

<style lang="scss">
  .lastFiveIp-popup {

    .modal-component__inner {
      max-width: 612px;
    }

    .site-table {
      td {
        white-space: initial;
      }

      .ellipsis {
        max-width: 120px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        display: inline-block;
      }
    }
  }
</style>
