export const radioButtonsFunc = {
  methods: {
    skipRadioChecked(prop, propItem, skipValue = false) {
      Object.keys(prop).map((item)=> {
        prop[item][propItem] = skipValue
      })
    },

    resetValues(array, resetValue = false) {
      Object.keys(array).map((item)=> {
        array[item] = resetValue
      })
    }
  }
}
