import {checkValid} from "../../../../globalModels/functions/ModelsValidate";

export function UserNote() {
  this.checkValid = checkValid

  this.data = {
    id: '',
    userId: '',
    text: '',
    sendEmail: false,
  }

  this.validation = {
    id: false,
    userId: false,
    text: false,
    sendEmail: false,
  }

  this.validationTranslate = {
    id: '',
    userId: '',
    text: '',
    sendEmail: '',
  }

  this.validationTxt = {
    id: false,
    userId: false,
    text: false,
    sendEmail: false,
  }

  /**
   * Getters
   */

  this.getId = () => {
    return this.data.id
  }
  this.getUserId = () => {
    return this.data.userId
  }
  this.getText = () => {
    return this.data.text
  }
  this.getSendEmail = () => {
    return this.data.sendEmail
  }

  /**
   * Setters
   */
  this.setId = (val) => {
    this.data.id = val
  }
  this.setUserId = (val) => {
    this.data.userId = val
  }
  this.setText = (val) => {
    this.data.text = val
  }
  this.setSendEmail = (val) => {
    this.data.sendEmail = val
  }
}

/**
 * Global Setters
 */

UserNote.prototype.setItem = function () {

}

/**
 * Prepare Data
 */

UserNote.prototype.prepareSave = function() {
  return  {
    'user_id': this.getUserId(),
    'note': this.getText(),
    'send_email': this.getSendEmail()
  }
}

/**
 * Validations
 */

UserNote.prototype.validationItem = function() {
  let validationItems = {
    text: this.getText(),
  }

  let validationOptions = {
    text: {type: ['empty']},
  }

  return this.checkValid(validationItems, validationOptions)
}