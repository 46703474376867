export default {
  name: "Breadcrumb",

  props:[
    'breadcrumbs',
    'hideBreadcrumbs'
  ],

  mounted() {

  },

  watch:{

  },

  data(){
    return{

    }
  },
}
