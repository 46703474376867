<template>
  <div class="custom-row">
    <div class="custom-col mb-0 position-relative"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <span class="admin-edit" @click="editTranslate(label ? [label] : ['common_user'])"></span>
      <div class="copy-name" v-if="copyName">
        <LinkButton
            :value="''"
            :type="'copy'"
            @click.native="copyUserName"
        />
      </div>
      <SearchSelect
              :options="options"
              :label="$t(`${label}.localization_value.value`)"
              :functionSearch="functionSearch"
              @change="changeUsersFilter"
              :selected="userSelected && Object.keys(userSelected).length > 0 ? userSelected : null"
              v-bind:class="{'ui-no-valid': validationUser}"
              :error="validationUser"
              :errorTxt="serverError ? validationTxtUser : $t(`${validationTranslateUser}.localization_value.value`)"
              :userEmail="userEmail"
              :typeSelect="typeSelect"
              :disabled="disabled"
      />
    </div>
  </div>

</template>

<script>
  import SearchSelect from "../../UI/selectiones/SearchSelect/SearchSelect";
  import {usersFunc} from "../../../mixins/usersMixins/usersFunc";
  import LinkButton from "@/components/UI/buttons/LinkButton/LinkButton";
  export default {
    name: "UserSelect",
    components: {LinkButton, SearchSelect},

    mixins: [usersFunc],

    props: {
      serverError: {
        type: Boolean,
        default: false,
      },
      validationUser: Boolean,
      validationTxtUser: {
        type: [Boolean, String]
      },
      validationTranslateUser: String,
      userSelected: [Object, String],
      userEmail: Boolean,

      typeSelect: {
        type: String,
        default: 'usersCreations',
      },
      disabled: {
        type: Boolean,
        default: false
      },
      forSearchType: {
        type: Boolean,
        default: false
      },
      copyName: {
        type: Boolean,
        default: false
      },
      label: {
        type: String,
        default: 'common_user'
      },
    },

    data() {
      return {
        options: [],
      }
    },

    methods: {

      changeUsersFilter(value) {
        this.$emit('changeUser', value)
      },

      functionSearch(value, loading) {
        loading(true)
        this.getFilteredUsers(value, this, 'options', loading, this.forSearchType)
      },

      copyUserName() {
        if(Object.keys(this.userSelected).length > 0) {
          this.copyToClipboard(this.userSelected.contacts[0].user_full_name)
          // if(this.userEmail) {
          //   this.copyToClipboard(this.userSelected.contacts[0].email)
          // } else {
          //   this.copyToClipboard(this.userSelected.contacts[0].user_full_name)
          // }
        }
      },
    },
  }
</script>

<style scoped>

  .copy-name {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: -17px;
  }

</style>
