
<template>
  <modal
      @close="$emit('close')"
      class="custom-popup-modal custom-popup comments-popup"
  >
    <template slot="header">
      {{$t('userNotes_Comments.localization_value.value')}}
    </template>
    <template slot="body">
      <div class="custom-popup__content">

        <div class="comments-block">
          <div class="comments-block__list">
            <div
                class="comments-block__item"
                v-for="(item, index) in userNotes"
                :key="index" >
              <div class="comments-block__time">
                {{ item.created_at | moment("DD MMM, YYYY") }} {{$t('userNotes_At.localization_value.value')}} {{ item.created_at | moment("HH:mm a") }}
              </div>
              <div class="comments-block__text">
                {{item.note}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from "../../../../../commonModals/Modal";

  export default {
    name: "CommentsPopup",
    components: {
      Modal,
    },

    props: {
      userId: Number,
    },

    data() {
      return {
        userNotes: []
      }
    },

    mounted() {
      this.$store.dispatch('getUserNotesById', this.userId).then(response => {
        this.userNotes = this.getRespData(response)
      })
    },

    methods: {

    }
  }
</script>

<style lang="scss">

  .comments-popup {

    .modal-component__inner {
      max-width: 612px;
    }
  }
</style>
