<template>
  <div v-if="isAdmin" class="white-space-normal"
       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
    <div class="admin-edit" @click="editTranslate([
      'userNotes_MailWithClient',
      'userNotes_ViewComments',
      'userNotes_LastFiveIP',
      'userNotes_ClientsStatistics',
      ])"></div>
    <div class="table-row" v-bind:class="{'d-flex flex-wrap': mobile}">
      <StatusIcoBtn
          v-if="getUserDocumentsChecked"
          class="mr-1 t-0"
          :type="'delivered'"
      />
      <LabelCircle
          v-if="getNewUserStatus"
          class="oval mr-1"
          :color="'blue'"
          :value="'N'"
      />
      <span class="table-link btn-style mr-1 white-space-line"
            @click="changeStatisticPopup(true)"
      >
        {{getUserName}}
      </span>

<!--      <span class="brick-btn mr-1">-->
<!--        F-->
<!--      </span>-->
      <span
          v-if="getUserATMOutput"
          class="brick-btn mr-1"
      >
        ATM
      </span>
      <span
          v-if="withCountry && getCountry"
          class="mr-1"
          style="font-size: 12px;"
      >
        {{getCountry.code_iso_2}}
        <span class="flag-small"
              v-bind:class="{
                'flag-small--ua': isUkraineCountryByUserData(getCountry.id),
                'flag-small--gb': isUnitedKingdomCountryByUserData(getCountry.id),
                'flag-small--pl': isPolandCountryByUserData(getCountry.id),
                'flag-small--lv': isLatviaCountryByUserData(getCountry.id),
              }"
        ></span>
      </span>

<!--                     v-if="!mobile"-->
      <ManagerButton class="alone more-180 ml-3 mb-1"
                     :isCalculate="false"
      >
        <template slot="item">
          <LinkButton
              :value="$t('userNotes_MailWithClient.localization_value.value')"
              :type="'letter'"
              @click.native="changeSendEmailPopup(true)"
          />
        </template>
        <template slot="item">
          <LinkButton
              :value="$t('userNotes_ViewComments.localization_value.value')"
              :type="'cloud-msg'"
              @click.native="changeCommentsPopup(true)"
          />
        </template>
        <template slot="item">
          <LinkButton
              :value="$t('userNotes_LastFiveIP.localization_value.value')"
              :type="'pin'"
              @click.native="changeLastFiveIPPopup(true)"
          />
        </template>
        <template slot="item">
          <LinkButton
                  v-if="item.user && !fromUsersTable"
              :value="$t('userNotes_ClientsStatistics.localization_value.value')"
              :type="'statistic'"
              :link="$store.getters.GET_PATHS.mainSettingsUsers + `?userId=${item.user.id}`"
          />
          <LinkButton
                  v-if="item.user && fromUsersTable"
              :value="$t('userNotes_ClientsStatistics.localization_value.value')"
              :type="'statistic'"
                  @click.native="$emit('filterByUser')"
          />
        </template>
      </ManagerButton>

<!--      <template v-else>-->
<!--        <LinkButton-->
<!--            class="mr-2"-->
<!--            :value="$t('userNotes_MailWithClient.localization_value.value')"-->
<!--            :type="'letter'"-->
<!--            @click.native="changeSendEmailPopup(true)"-->
<!--        />-->

<!--        <LinkButton-->
<!--            class="mr-2"-->
<!--            :value="$t('userNotes_ViewComments.localization_value.value')"-->
<!--            :type="'cloud-msg'"-->
<!--            @click.native="changeCommentsPopup(true)"-->
<!--        />-->

<!--        <LinkButton-->
<!--            class="mr-2"-->
<!--            :value="$t('userNotes_LastFiveIP.localization_value.value')"-->
<!--            :type="'pin'"-->
<!--            @click.native="changeLastFiveIPPopup(true)"-->
<!--        />-->

<!--        <LinkButton-->
<!--            v-if="item.user"-->
<!--            :value="$t('userNotes_ClientsStatistics.localization_value.value')"-->
<!--            :type="'statistic'"-->
<!--            :link="$store.getters.GET_PATHS.mainSettingsUsers + `?userId=${item.user.id}`"-->
<!--        />-->
<!--      </template>-->
    </div>

    <CommentsPopup
        v-if="isModalCommentsPopup"
        :userId="item.user.id"
        @close="changeCommentsPopup(false)"
    />

    <SendEmailPopup
        v-if="isModalSendEmailPopup"
        :userId="item.user.id"
        :userName="getUserName"
        @close="changeSendEmailPopup(false)"
    />

    <LastFiveIPPopup
        v-if="isModalLastFiveIPPopup"
        :userId="item.user.id"
        :userName="getUserName"
        @close="changeLastFiveIPPopup(false)"
    />

    <StatisticPopup
        v-if="isModalStatisticPopup"
        :userId="item.user.id"
        :userName="getUserName"
        @close="changeStatisticPopup(false)"
    />

  </div>
</template>

<script>
  import StatusIcoBtn from "../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import LabelCircle from "../../LabelCircle/LabelCircle";
  import ManagerButton from "../../../UI/buttons/ManagerButton/ManagerButton";
  import LinkButton from "../../../UI/buttons/LinkButton/LinkButton";
  import CommentsPopup from "./popups/CommentsPopup/CommentsPopup";
  import SendEmailPopup from "./popups/SendEmailPopup/SendEmailPopup";
  import LastFiveIPPopup from "./popups/LastFiveIPPopup/LastFiveIPPopup";
  import StatisticPopup from "./popups/StatisticPopup/StatisticPopup";

  export default {
    name: "TableUserColumn",
    components: {
      StatisticPopup,
      LastFiveIPPopup,
      SendEmailPopup,
      CommentsPopup,
      LinkButton,
      ManagerButton,
      LabelCircle,
      StatusIcoBtn
    },

    props: {
      item: Object,
      mobile: {
        type: Boolean,
        default: false,
      },
      fromUsersTable: {
        type: Boolean,
        default: false
      },
      withCountry: {
        type: Boolean,
        default: false
      },
    },

    computed: {
      getUserName() {
        if (!this.item?.user) return ''

        if (this._.has(this.item.user, 'user_personal_contact')) {
          return this.item.user.user_personal_contact.user_full_name
        } else {
          return this.item.user.contacts[0].user_full_name
        }
      },

      getNewUserStatus() {
        return this.item?.user?.show_n_mark
      },
      getUserDocumentsChecked() {
        return this.item?.user?.user_setting?.documents_checked
      },
      getUserATMOutput() {
        return this.item?.user?.user_setting?.atm_output === '1'
      },

      getCountry() {
        return this.getUserContactWithCheckAlternative(this.item?.user)?.country //this.item?.user?.user_personal_contact?.country
      },
    },

    data() {
      return {
        isModalCommentsPopup: false,
        isModalSendEmailPopup: false,
        isModalLastFiveIPPopup: false,
        isModalStatisticPopup: false,
      }
    },

    methods: {
      changeCommentsPopup(val) {
        this.isModalCommentsPopup = val
      },
      changeSendEmailPopup(val) {
        this.isModalSendEmailPopup = val
      },
      changeLastFiveIPPopup(val) {
        this.isModalLastFiveIPPopup = val
      },
      changeStatisticPopup(val) {
        this.isModalStatisticPopup = val
      },
    },

  }
</script>

<style scoped lang="scss">

  .flag-small {
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    top: 3px;

    &--ua {
      background: url("../../../../assets/img/flags/ukraine.svg") center center no-repeat;
    }

    &--gb {
      background: url("../../../../assets/img/flags/united-kingdom.svg") center center no-repeat;
    }

    &--pl {
      background: url("../../../../assets/img/flags/poland.svg") center center no-repeat;
    }

    &--lv {
      background: url("../../../../assets/img/flags/latvia.svg") center center no-repeat;
    }
  }

</style>
