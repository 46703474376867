<template>
  <div class="fragment" style="flex-shrink: 0">
    <div class="tooltip-helper"
         v-bind:class="{'max-width-helper': fullTextWidth}"
         v-if="(text && text.length > 0) || $slots.text">
      <CustomTooltip
          :widthPopover="widthPopover"
      >
        <template slot="main-text">
          <div class="tooltip-helper-full"
               v-bind:style="{maxWidth: getMaxWidth}"
               :class="[styleClass]">
            {{text}}
            <slot name="text"></slot>
          </div>
        </template>
        <template slot="popover">
          <p class="tooltip-helper-title" v-if="title || $slots.title">
            {{title}}
            <slot name="title"></slot>
          </p>
          <p class="tooltip-helper-paragraph" v-if="paragraph || $slots.paragraph">
            {{paragraph}}
            <slot name="paragraph"></slot>
          </p>
          <p class="tooltip-helper-second-paragraph" v-if="secondParagraph">
            {{secondParagraph}}
          </p>
        </template>
      </CustomTooltip>
<!--      <v-popover-->
<!--          class="site-tooltip"-->
<!--          :offset="offset"-->
<!--          :placement="placement"-->
<!--          :trigger="trigger"-->
<!--      >-->
<!--        <div class="tooltip-helper-full"-->
<!--             v-bind:style="{maxWidth: getMaxWidth}"-->
<!--             :class="[styleClass]">-->
<!--          {{text}}-->
<!--          <slot name="text"></slot>-->
<!--        </div>-->
<!--        <template slot="popover">-->
<!--          <p class="tooltip-helper-title" v-if="title || $slots.title">-->
<!--            {{title}}-->
<!--            <slot name="title"></slot>-->
<!--          </p>-->
<!--          <p class="tooltip-helper-paragraph" v-if="paragraph || $slots.paragraph">-->
<!--            {{paragraph}}-->
<!--            <slot name="paragraph"></slot>-->
<!--          </p>-->
<!--          <p class="tooltip-helper-second-paragraph" v-if="secondParagraph">-->
<!--            {{secondParagraph}}-->
<!--          </p>-->
<!--        </template>-->
<!--      </v-popover>-->
    </div>
    <div v-else>
      —
    </div>
  </div>
</template>

<script>
  import CustomTooltip from "@/components/coreComponents/CustomTooltip/CustomTooltip";
  export default {
    name: "TooltipTextHelper",
    components: {CustomTooltip},
    props: {
      text: {
        type: [String, Number],
        default: '',
      },
      title: {
        type: [String, Number],
        default: null,
      },
      paragraph: {
        type: [String, Number],
        default: null,
      },
      secondParagraph: {
        type: [String, Number],
        default: null,
      },
      offset:{
        type: String,
        default: "5",
      },
      placement:{
        type: String,
        default: "top",
      },
      trigger:{
        type: String,
        default: "hover",
      },
      styleClass:{
        type: String,
        default: '',
      },
      fullTextWidth:{
        type: [String, Number],
        default: null,
      },
      widthPopover:{
        type: [String, Number],
        default: null,
      },
    },

    computed: {
      getMaxWidth() {
        return this.fullTextWidth ? this.fullTextWidth + 'px' : 'initial'
      }
    }

  }
</script>

<style scoped lang="scss">
  .bold .tooltip-helper-full{
    font-weight: bold;
  }

  .max-width-helper .tooltip-helper-full{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }

</style>
