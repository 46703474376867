<template>
  <div class="user-info-admin">
    <div class="user-info-admin__inner"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
                          'payments_Editing',
                          'common_CheckHistory',
                        ])"></div>
      <div class="user-info-admin__caption">
        {{ $t('payments_Editing.localization_value.value') }}
      </div>
      <div class="user-info-admin__large">
        {{ $store.getters.getNewPaymentsPayoneerItem.created_at | moment("DD MMM, YYYY") }}
      </div>
      <div class="user-info-admin__history">

<!--        <div class="site-link brown text-decoration-underline">-->
<!--          <div v-if="isAdmin && pm.newPayments.historyOfChanged"-->
<!--               class="mb-3"-->
<!--               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">-->
<!--            <div class="admin-edit" @click="editTranslate(['common_CheckHistory'])"></div>-->
<!--            <div class="site-link text-decoration-underline btn-style brown"-->
<!--                 @click="historyOfChangesFunc('newPayments', pm.newPayments.historyOfChanged)">-->
<!--              {{$t('common_CheckHistory.localization_value.value')}}-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
      </div>

    </div>



  </div>
</template>

<script>


  export default {
    name: "UserInfo",
    components: {
    },

    props: {
      pm: Object,
    },

    data(){
      return{
        isModalHistoryOfChanges: false,
      }
    },

    methods:{
      changeHistoryOfChanges(val){
        this.isModalHistoryOfChanges= val
      },
    }
  }
</script>

<style scoped>

</style>
