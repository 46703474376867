export const usersFunc = {

  data() {
    return {
      userFilterTimeoutTime: undefined,
    }
  },

  methods: {
    getFilteredUsers(value, that, optionName, loading, forSearch = false) {

      if(this.userFilterTimeoutTime !== undefined) {
        let dateNow = new Date()
        if(dateNow - this.userFilterTimeoutTime < 500){
          console.log(dateNow - this.userFilterTimeoutTime)
          clearTimeout(this.userFilterTimeout)
          createTimeOut()
        }
      } else {
        createTimeOut()
      }

      function createTimeOut() {
        that.userFilterTimeoutTime = new Date()
        that.userFilterTimeout = setTimeout(() => {
          search()
        },800)
      }

      function search() {
        that.userFilterTimeoutTime = undefined

        if(value.length === 0) {
          that[optionName] = []
        }
        if(value.length < 1) {
          loading(false)
          return
        }

        const query = new that.Query();
        let url = '?';
        let myQuery = query
          .for('posts')

        myQuery.where('user_select_filter', value)
        // myQuery.where('UserEmail', value)

        let queryStringName = 'fetchUsersFromFilter'
        if (forSearch) {
          queryStringName = 'fetchUsersFromFilterForSearch'
        }

        url = url + myQuery.limit(100000).page(1).url().split('?')[1]
        that.$store.dispatch(queryStringName, url).then(response => {
          that[optionName] = response.data.data.data
          loading(false)
        })
      }

    },


    getFilteredAdminUsers(value, that, optionName, loading) {

      if(this.userFilterTimeoutTime !== undefined) {
        let dateNow = new Date()
        if(dateNow - this.userFilterTimeoutTime < 500){
          console.log(dateNow - this.userFilterTimeoutTime)
          clearTimeout(this.userFilterTimeout)
          createTimeOut()
        }
      } else {
        createTimeOut()
      }

      function createTimeOut() {
        that.userFilterTimeoutTime = new Date()
        that.userFilterTimeout = setTimeout(() => {
          search()
        },800)
      }

      function search() {
        that.userFilterTimeoutTime = undefined

        if(value.length === 0) {
          that[optionName] = []
        }
        if(value.length < 1) {
          loading(false)
          return
        }

        const query = new that.Query();
        let url = '?';
        let myQuery = query
          .for('posts')

        myQuery.where('user_select_filter', value)
        // myQuery.where('UserEmail', value)
        url = url + myQuery.limit(100000).page(1).url().split('?')[1]
        that.$store.dispatch('fetchUsersFromFilter', url).then(response => {
          that[optionName] = response.data.data.data
          loading(false)
        })
      }

    },
  },


}
