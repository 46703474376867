<template>
  <NewPaymentsPayoneerCreateAdmin
          :NPP="NPP"
          :serverError="serverError"
          @createPayment="createPayment"
  />
</template>

<script>
  import NewPaymentsPayoneerCreateAdmin from "./NewPaymentsPayoneerCreateAdmin/NewPaymentsPayoneerCreateAdmin";
  import {newPaymentsPayoneerMixin} from "@/mixins/newPaymentsPayoneerMixins/newPaymentsPayoneerMixin";
  import {NewPaymentsPayoneer} from "@/components/modules/NewPaymentsPayoneerModule/models/NewPaymentsPayoneer";

  export default {
    name: "NewPaymentsPayoneerCreate",

    components: {
      NewPaymentsPayoneerCreateAdmin,
    },

    mixins: [newPaymentsPayoneerMixin],

    data() {
      return {
        NPP: new NewPaymentsPayoneer(),
        serverError: false,
      }
    },

    mounted() {

    },

    methods: {


    },

  }
</script>

<style scoped>

</style>
