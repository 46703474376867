<template>
  <modal
      @close="$emit('close')"
      class="transaction-info-modal transaction-info"
  >
    <template slot="header">
      #{{getNewPaymentsPayoneerItem.id}}
    </template>
    <template slot="body">
      <div class="transaction-info__content"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <span class="admin-edit" @click="editTranslate([
          'payments_NewPayment',
          'common_Amount',
          'payments_comment',
        ])"></span>
        <div class="site-link brown text-decoration-underline">
          <div v-if="isAdmin && getNewPaymentsPayoneerItem.activityUUID"
               class="mb-3"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate(['common_CheckHistory'])"></div>
            <div class="site-link text-decoration-underline btn-style brown"
                 @click="historyOfChangesFunc('newPayments', getNewPaymentsPayoneerItem.activityUUID)">
              {{$t('common_CheckHistory.localization_value.value')}}
            </div>
          </div>
        </div>

        <div class="transaction-info__name">
          <ValueHelper
              :value="getNewPaymentsPayoneerItem"
              :deep="'user.user_personal_contact.user_full_name'"
          />
        </div>
        <div class="transaction-info__name"
             v-if="item = getNewPaymentsPayoneerItem"
        >
          <div class="table-row"
               v-if="item.status === NEW_PAYMENTS_PAYONEER_STATUSES.COMPLETE">
            <StatusIcoBtn :type="'active'" class="mr-2 t-0"/>
            {{$t('common_Paid.localization_value.value')}}
          </div>
          <div class="table-row"
               v-if="item.status === NEW_PAYMENTS_PAYONEER_STATUSES.NEW">
            <StatusIcoBtn :type="'star'" class="mr-2 t-0"/>
            {{$t('common_statusNew.localization_value.value')}}
          </div>
        </div>

        <div class="transaction-info__section">

          <div class="transaction-info__row">
            <div class="custom-col">
              <div class="transaction-info__label">
                {{$t('payments_Created.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                {{ item.created_at | moment("DD MMM, YYYY") }}
              </div>
            </div>
            <div class="custom-col">
              <div class="transaction-info__label">
                {{$t('common_Amount.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                    :value="getNewPaymentsPayoneerItem"
                    :deep="'amount'"
                />
              </div>
            </div>
            <div class="custom-col" v-if="getNewPaymentsPayoneerItem.transaction_id">
              <div class="transaction-info__label">
                {{$t('importO_transactionNumber.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                    :value="getNewPaymentsPayoneerItem"
                    :deep="'transaction_id'"
                />
              </div>
            </div>
            <div class="custom-col">
              <div class="transaction-info__label">
                {{$t('payments_comment.localization_value.value')}}
              </div>
              <div class="transaction-info__txt">
                <ValueHelper
                    :value="getNewPaymentsPayoneerItem"
                    :deep="'comment'"
                />
              </div>
            </div>

          </div>

        </div>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '../../../../commonModals/Modal.vue'
  import StatusIcoBtn from "../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  import {NEW_PAYMENTS_PAYONEER_STATUSES} from "../../../../../staticData/staticVariables";
  import {mapGetters} from "vuex";
  import ValueHelper from "@/components/coreComponents/ValueHelper/ValueHelper";


  export default {
    name: "InfoIDPopup",
    components: {
      ValueHelper,
      StatusIcoBtn,
      Modal,
    },

    computed: {
      ...mapGetters([
        'getNewPaymentsPayoneerItem',
      ])
    },

    data() {
      return{
        // PAYMENTS_STATUSES: CUSTOMERS_PAYMENTS_STATUSES,
        NEW_PAYMENTS_PAYONEER_STATUSES: NEW_PAYMENTS_PAYONEER_STATUSES,
      }
    },

    methods: {
      addCommission(id, val) {
        let data = {
          paymentTransactionId: id,
          processType: val ? 1 : 0,
        }
        this.$store.dispatch('changeWarehouseCommission', data).then(response => {
          if(!response.data.data.status){
            this.openNotify('error', 'common_notificationUndefinedError')
          }
          else {
            this.openNotify('success', 'common_notificationStatusChanged')
            this.$emit('reload')
            this.$emit('close')
          }
        })
      },
    },
  }

</script>

<style lang="scss">

</style>
