<template>
  <div class="detail-page reverse">
    <div class="detail-page__left">
      <CardLeftBlock
          class="clear-992"
          :backgroundImage="'product-admin'"
      >
        <template slot="body">
          <UserInfo/>
        </template>
      </CardLeftBlock>
    </div>

    <div class="detail-page__right">
      <CardRightBlock>
        <template slot="header">
          <div class="order-create__head">
            <div class="order-create__head-line">
              <div class="order-create__header-col">
                <router-link :to="$store.getters.GET_PATHS.financeNewPaymentsPayoneer" class="detail-page__head-back">
                  <LinkBack
                          :value="$t('ex_backTo.localization_value.value')"
                  />
                </router-link>
              </div>
            </div>
            <div class="order-create__head-title"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                          'payments_NewPayment',
                        ])"></div>
              {{$t('payments_NewPayment.localization_value.value')}}
            </div>
            <div class="order-create__head-txt mb-2"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                          'payments_NewPaymentDecs',
                        ])"></div>
              {{$t('payments_NewPaymentDecs.localization_value.value')}}
            </div>
          </div>
        </template>

        <template slot="body">
          <form class="order-create__section">
            <div class="order-create__row custom-row">
              <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100">
                <UserSelect
                        :serverError="serverError"
                        :validationUser="NPP.validation.user"
                        :validationTxtUser="NPP.validationTxt.user"
                        :validationTranslateUser="NPP.validationTranslate.user"
                        :userSelected="NPP.newPayments.user"
                        @changeUser="changeUser"
                        :typeSelect="'users'"
                        :userEmail="true"
                />
              </div>
              <div class="order-create__col custom-col custom-col--16 custom-col--md-50 custom-col--sm-100"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                          'common_Amount',
                        ])"></div>
                <InputSum
                        :label="$t('common_Amount.localization_value.value')"
                        :icoType="'dollar'"
                        @change="changeValueQuantity"
                        :placeholder="'0'"
                        v-bind:class="{'ui-no-valid': NPP.validation.amount}"
                        :errorTxt="serverError ? NPP.validationTxt.amount : $t(`${NPP.validationTranslate.amount}.localization_value.value`)"
                        :error="NPP.validation.amount"
                        :value="NPP.newPayments.amount"
                />
              </div>
            </div>
            <div class="order-create__row custom-row" v-if="isAdmin">
              <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <DefaultInput
                    :label="$t('importO_transactionNumber.localization_value.value')"
                    v-bind:class="{'ui-no-valid': NPP.validation.transaction}"
                    :error="NPP.validation.transaction"
                    :errorTxt="$t(`${NPP.validationTranslate.transaction}.localization_value.value`)"
                    v-model="NPP.newPayments.transaction"
                />
              </div>
            </div>
            <div class="order-create__row custom-row">
              <div class="custom-col custom-col--66 custom-col--md-100"
                   v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
                <div class="admin-edit" @click="editTranslate([
                          'payments_comment',
                        ])"></div>
                <TextareaDefault
                        :label="$t('payments_comment.localization_value.value')"
                    :placeholder="'Please be careful with the vase inside!'"
                    v-bind:class="{'ui-no-valid': NPP.validation.comment}"
                    :errorTxt="serverError ? NPP.validationTxt.comment : $t(`${NPP.validationTranslate.comment}.localization_value.value`)"
                    :error="NPP.validation.comment"
                    v-model="NPP.newPayments.comment"
                />
              </div>
            </div>
          </form>
        </template>
        <template slot="footer">
          <div class="order-create__footer">
            <div class="order-create__footer-link">
              <router-link :to="$store.getters.GET_PATHS.financeNewPaymentsPayoneer"
                           class="site-link site-link--alt"
              >
                {{$t('common_cancel.localization_value.value')}}
              </router-link>

            </div>

            <div class="order-create__footer-btn ml-auto"
                 v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
              <div class="admin-edit" @click="editTranslate([
                          'common_Pay',
                          'common_create',
                        ])"></div>

              <MainButton
                      class="order-create__footer-btn-i wfc mr-1"
                      :value="$t('common_Pay.localization_value.value')"
                      @click.native="$emit('createPayment', true)"
                      v-bind:class="{'disabled-btn' : $store.getters.getNewPaymentsPayoneerBtn}"
              />
              <MainButton
                  class="order-create__footer-btn-i wfc"
                  :value="$t('common_create.localization_value.value')"
                  @click.native="$emit('createPayment')"
                  v-bind:class="{'disabled-btn' : $store.getters.getNewPaymentsPayoneerBtn}"
              />

            </div>
          </div>
        </template>
      </CardRightBlock>
    </div>

    <!--Oleksandr Barabanov-->
    <!--sagapottery@gmail.com-->

  </div>
</template>

<script>
  import MainButton from "../../../../../UI/buttons/MainButton/MainButton";
  import CardLeftBlock from "../../../../../coreComponents/CardLeftBlock/CardLeftBlock.vue";
  import UserInfo from "./components/UserInfo/UserInfo";
  import CardRightBlock from "../../../../../coreComponents/CardRightBlock/CardRightBlock.vue";
  import LinkBack from "../../../../../UI/buttons/LinkBack/LinkBack";
  // import InputQuantity from "../../../../../UI/inputs/InputQuantity/InputQuantity";
  import TextareaDefault from "../../../../../UI/textarea/TextareaDefault/TextareaDefault";
  // import DefaultSelect from "../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
  import UserSelect from "../../../../../coreComponents/UserSelect/UserSelect";
  import InputSum from "../../../../../UI/inputs/InputSum/InputSum";
  import DefaultInput from "@/components/UI/inputs/DefaultInput/DefaultInput";

  export default {
    name: "NewPaymentsPayoneerCreateAdmin",
    components: {
      DefaultInput,
      InputSum,
      UserSelect,
      MainButton,
      CardLeftBlock,
      UserInfo,
      CardRightBlock,
      LinkBack,
      // InputQuantity,
      TextareaDefault,
      // DefaultSelect,
    },

    props: {
      NPP: Object,
      serverError: Boolean,
    },

    data(){
      return{
        options: [{}],
        valueQuantity: 100,
        comment: '',
      }
    },

    methods:{
      changeUser(user) {
        this.NPP.setUser(user)
      },

      changeValueQuantity(data) {
        this.NPP.setAmount(data)
      },
    },

  }
</script>

<style scoped>

</style>
