<template>
  <NewPaymentsPayoneerEditAdmin
          :NPP="NPP"
          :serverError="serverError"
          :requestType="requestType"
          @updatePayment="updatePayment"
          @pay="pay"
  />
</template>

<script>
  import NewPaymentsPayoneerEditAdmin from "./NewPaymentsPayoneerEditAdmin/NewPaymentsPayoneerEditAdmin";
  import {NewPaymentsPayoneer} from "../../models/NewPaymentsPayoneer";
  import {checkAccess} from "@/mixins/userCredentionalMixins/userCredentional";
  // import {NEW_PAYMENTS_PAYONEER_STATUSES} from "../../../../../staticData/staticVariables";
  import {newPaymentsPayoneerMixin} from "@/mixins/newPaymentsPayoneerMixins/newPaymentsPayoneerMixin";

  export default {
    name: "NewPaymentsPayoneerEdit",

    components: {
      NewPaymentsPayoneerEditAdmin,
    },

    mixins: [newPaymentsPayoneerMixin, checkAccess],

    data() {
      return {
        NPP: new NewPaymentsPayoneer(),
        serverError: false,
        requestType: false,
      }
    },

    mounted() {

      if(this.$route.query.request) {
        this.requestType = true
      }

      this.$store.dispatch('getNewPaymentsPayoneer', this.$route.params.id).then(() => {
        this.NPP.setNewPaymentPayoneerItem(this.$store.getters.getNewPaymentsPayoneerItem)
      }).catch(error => this.createErrorLog(error))
    },

    methods: {

      pay() {
        this.newPaymentPayoneerAccess(this.NPP.newPayments.id).then(response => {
          if(response){
            this.payPaymentPayoneer(this.NPP.newPayments.id, {goToTable: true})
          }
        })
      },

      updatePayment() {
        if(!this.NPP.newPaymentsPayoneerValidate()) return

        let data = this.NPP.newPaymentsPayoneerPrepare()

        let updateRequestType = 'updateNewPaymentsPayoneer'

        this.$store.dispatch(updateRequestType, {data: data, id: this.NPP.newPayments.id}).then((response) => {
          switch (this.getResponseStatus(response)) {
              /**
               * Success
               */
            case this.$store.getters.GET_ERRORS.SUCCESS_CODE: {
              this.openNotify('success', 'common_notificationRecordChanged')
              this.$router.push(this.$store.getters.GET_PATHS.financeNewPaymentsPayoneer)
              break
            }
              /**
               * Validation Error
               */
            case this.$store.getters.GET_ERRORS.VALIDATION_ERROR_CODE: {
              let errors = response.response.data.errors;
              this.notifyErrorHelper(errors)
              break
            }
              /**
               * Undefined Error
               */
            default: {
              this.openNotify('error', 'common_notificationUndefinedError')
            }
          }
        })
      },
    },

  }
</script>

<style scoped>

</style>
