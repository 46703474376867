<template>
  <div class="table-card__item-content"
       :class="{show: show}"
  >
    <div class="table-card__item-head">
      <div class="table-card__item-head-row d-flex">
<!--        <DefaultCheckbox class="mt-1"-->
<!--                         :dataValue="item.id"-->
<!--                         v-if="item.status !== NEW_PAYMENTS_PAYONEER_STATUSES.COMPLETE"-->
<!--        />-->
        <div class="table-card__item-number table-card__item-number--small cursor-pointer"
             @click="$emit('showInfoPopup')"
        >
          #{{item.id}}
        </div>
      </div>
      <div class="table-card__item-head-row">
        <div class="table-card__item-head-txt">
          {{ item.created_at | moment("DD MMM, YYYY") }}
        </div>
      </div>
    </div>
    <transition name="slide">
      <div class="table-card__item-body"
           v-if="show"
      >
        <div class="table-card__item-row custom-row">
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('payments_Created.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{ item.created_at | moment("DD MMM, YYYY") }}
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('payments_Total.localization_value.value')}},$
            </div>
            <div class="table-card__item-info">
              {{item.amount}}
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50">
            <div class="table-card__item-label">
              {{$t('importO_transactionNumber.localization_value.value')}},$
            </div>
            <div class="table-card__item-info">
              {{item.transaction_id}}
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50" v-if="navTabs.requests.active">
            <div class="table-card__item-label">
              {{$t('common_email.localization_value.value')}},$
            </div>
            <div class="table-card__item-info">
              {{item.payoneer_from_email}}
            </div>
          </div>
          <div class="table-card__item-col custom-col" v-if="isAdmin">
            <div class="table-card__item-label">
              {{$t('common_user.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <TableUserColumn
                  v-if="item.user"
                      :item="item"
                      :mobile="true"
              />
              <template v-else>—</template>
            </div>
          </div>

          <div class="table-card__item-col custom-col">
            <div class="table-card__item-label">
              {{$t('payments_comment.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              <TooltipTextHelper
                  :text="item.comment"
                  :paragraph="item.comment"
                  :fullTextWidth="290"
              />
            </div>
          </div>

          <div class="table-card__item-col custom-col" v-if="isAdmin">
            <div class="table-card__item-label">
              {{$t('payments_type.localization_value.value')}}
            </div>
            <div class="table-card__item-info">
              {{item.create_type}}
            </div>
          </div>
          <div class="table-card__item-col custom-col custom-col--50" v-if="navTabs.payments.active">
            <div class="table-card__item-label">
              {{$t('payments_Status.localization_value.value')}}
            </div>
            <div class="table-card__item-info"
                 v-if="item.status === NEW_PAYMENTS_PAYONEER_STATUSES.COMPLETE">
              <StatusIcoBtn :type="'active'" class="mr-2 t-0"/>
              {{$t('common_Paid.localization_value.value')}}
            </div>
            <div class="table-card__item-info"
                 v-if="item.status === NEW_PAYMENTS_PAYONEER_STATUSES.NEW">
              <StatusIcoBtn :type="'star'" class="mr-2 t-0"/>
              {{$t('common_statusNew.localization_value.value')}}
            </div>
          </div>
        </div>
        <div class="table-card__item-btn pl-2"
             v-if="isAdmin">
          <div v-if="item.status !== NEW_PAYMENTS_PAYONEER_STATUSES.COMPLETE" class="table-card__item-btn-i">
            <LinkButton
                    :value="$t('common_Pay.localization_value.value')"
                    :type="'dollar'"
                    @click.native="$emit('payPayment')"
            />
          </div>
          <div class="table-card__item-btn-i" v-if="navTabs.payments.active || isAdmin">
            <LinkButton
                    :value="$t('common_edit.localization_value.value')"
                    :type="'edit'"
                    :link="$store.getters.GET_PATHS.financeNewPayment + '/edit/' + item.id + `${navTabs.requests.active ? '?request=true' : ''}`"
            />
          </div>
          <div v-if="item.status !== NEW_PAYMENTS_PAYONEER_STATUSES.COMPLETE" class="table-card__item-btn-i">
            <LinkButton
                    :value="$t('common_delete.localization_value.value')"
                    :type="'delete'"
                    @click.native="$emit('removePayment')"
            />
          </div>
        </div>
      </div>
    </transition>
    <div class="table-card__item-footer">
      <div class="table-card__item-show"
           @click="show = !show"
      >
        {{$t('common_showMore.localization_value.value')}}
      </div>
    </div>
  </div>
</template>

<script>
  import LinkButton from "../../../../../../../UI/buttons/LinkButton/LinkButton";
  import StatusIcoBtn from "../../../../../../../UI/status/StatusIcoBtn/StatusIcoBtn";
  // import DefaultCheckbox from "../../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import {
    NEW_PAYMENTS_PAYONEER_STATUSES
  } from "../../../../../../../../staticData/staticVariables";
  import TableUserColumn from "../../../../../../../coreComponents/TableComponents/TableUserColumn/TableUserColumn";
  import TooltipTextHelper from "../../../../../../../coreComponents/TooltipTextHelper/TooltipTextHelper";

  export default {
    name: "NewPaymentsPayoneerTableMobile",
    components: {
      TooltipTextHelper,
      TableUserColumn,
      // DefaultCheckbox,
      StatusIcoBtn,
      LinkButton
    },

    props: {
      item: Object,
      navTabs: Object,
    },

    data() {
      return {
        show: false,
        // PAYMENTS_STATUSES: CUSTOMERS_PAYMENTS_STATUSES,
        NEW_PAYMENTS_PAYONEER_STATUSES: NEW_PAYMENTS_PAYONEER_STATUSES,
      }
    },


  }
</script>

<style scoped>

</style>
