<template>
  <div class="search-engine-block">
    <div class="search-engine-block__inner">
      <div class="search-engine-block__title"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit" @click="editTranslate(['searchEngine_searchEngine'])"></span>
        {{$t('searchEngine_searchEngine.localization_value.value')}}
        <span class="search-engine-block__title-show"
              :class="{'active' : isShowSearchEngineBlock}"
              @click="showSearchEngineBlock"
        ></span>
      </div>
      <div class="search-engine-block__list"
           :class="{'show' : isShowSearchEngineBlock}"
      >
        <div class="search-engine-block__row custom-row">
          <div class="search-engine-block__col custom-col custom-col--25 custom-col--md-50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['searchEngine_orders'])"></span>
            <SearchInput
                :type="'text'"
                :label="$t('searchEngine_orders.localization_value.value')"
                v-model="fbmSearchField"
                @submit="search($event, 'fbm')"
                @keyup.native="search($event, 'fbm', true)"
            />
          </div>
          <div class="search-engine-block__col custom-col custom-col--25 custom-col--md-50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['searchEngine_express'])"></span>
            <SearchInput
                :type="'text'"
                :label="$t('searchEngine_express.localization_value.value')"
                v-model="expressSearchField"
                @submit="search($event, 'express')"
                @keyup.native="search($event, 'express', true)"
            />
          </div>
          <div class="search-engine-block__col custom-col custom-col--25 custom-col--md-50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['searchEngine_consolidations'])"></span>
            <SearchInput
                :type="'text'"
                :label="$t('searchEngine_consolidations.localization_value.value')"
                v-model="consoSearchField"
                @submit="search($event, 'consolidation')"
                @keyup.native="search($event, 'consolidation', true)"
            />
          </div>
          <div class="search-engine-block__col custom-col custom-col--25 custom-col--md-50 custom-col--sm-100"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <span class="admin-edit" @click="editTranslate(['searchEngine_shipment'])"></span>
            <SearchInput
                :type="'text'"
                :label="$t('searchEngine_shipment.localization_value.value')"
                v-model="inboundSearchField"
                @submit="search($event, 'inbound')"
                @keyup.native="search($event, 'inbound', true)"
            />
          </div>
        </div>
      </div>
    </div>

    <InformationPopup
        v-if="showNoResultsPopup"
        :title="'common_noResult'"
        :txt="'common_noSearch'"
        @close="showNoResultsPopup = false"
    />
  </div>
</template>

<script>
  import SearchInput from "../../UI/inputs/SearchInput/SearchInput";
  import InformationPopup from "../Popups/InformationPopup/InformationPopup";
  import {FBMMixinsHelper} from "../../../mixins/FBMMixins/FBMMixins";
  import {expressMixins} from "../../../mixins/expressMixins/expressMixin";
  import {consolidationMixin} from "../../../mixins/consolidationMixins/consolidationMixin";
  import {ordersInboundMixin} from "../../../mixins/ordersInbound/ordersInboundMixin";

  export default {
    name: "SearchEngine",
    components: {
      SearchInput,
      InformationPopup,
    },

    mixins: [
      FBMMixinsHelper,
      expressMixins,
      consolidationMixin,
      ordersInboundMixin
    ],

    data(){
      return{
        isShowSearchEngineBlock: false,
        showNoResultsPopup: false,
        fbmSearchField: '',
        expressSearchField: '',
        consoSearchField: '',
        inboundSearchField: '',
      }
    },

    methods: {
      showSearchEngineBlock(){
        this.isShowSearchEngineBlock = !this.isShowSearchEngineBlock;
      },

      search(e, type, checkPressedKey = false) {
        if (checkPressedKey && e.code !== 'Enter' && e.code !== 'NumpadEnter') return

        let activeInput = ''
        let requestType = ''
        let link = ''
        const query = new this.Query();
        let url = '?';
        let myQuery = query
            .for('posts')

        let hasPermission = true

        switch (type) {
          case 'fbm': {
            if(!this._.has(this.currentPermissions, this.PERMISSIONS.ORDER_FBM_GENERAL)){
              hasPermission = false
            }

            activeInput = this.fbmSearchField
            requestType = 'fetchOrdersFBM'
            myQuery.where('OrderFbmId', activeInput)
            break
          }
          case 'express': {
            if(!this._.has(this.currentPermissions, this.PERMISSIONS.ORDER_EXPRESS_GENERAL)){
              hasPermission = false
            }

            activeInput = this.expressSearchField
            requestType = 'fetchExpress'
            myQuery.where('OrderExpressId', activeInput)
            break
          }
          case 'consolidation': {
            if(!this._.has(this.currentPermissions, this.PERMISSIONS.CONSOLIDATION_GENERAL)){
              hasPermission = false
            }

            activeInput = this.consoSearchField
            requestType = 'fetchConsolidation'
            myQuery.where('ConsolidationId', activeInput)
            break
          }
          case 'inbound': {
            if(!this._.has(this.currentPermissions, this.PERMISSIONS.ORDER_INBOUND_GENERAL)){
              hasPermission = false
            }

            activeInput = this.inboundSearchField
            requestType = 'fetchOrdersInbound'
            myQuery.where('id', activeInput)
            break
          }
          default:
            break
        }

        if (!activeInput) return

        if (!hasPermission) {
          this.showNoResultsPopup = true
          return
        }

        url = url + myQuery.limit(10).page(0).url().split('?')[1]

        this.$store.dispatch(requestType, {filter: url, noCommit: true}).then(response => {
          let queryResults = this.getRespPaginateData(response)

          if (queryResults.length === 0) {
            this.showNoResultsPopup = true
            return
          }

          let foundItem = queryResults[0]

          console.log(response, foundItem);

          switch (type) {
            case 'fbm': {
              console.log(111, foundItem.order_type_id, this.getOrderFBMTypeById(foundItem.order_type_id));
              link = this.$store.getters.GET_PATHS.ordersFBM + '/' + this.getOrderFBMTypeById(foundItem.order_type_id).name + '/'
                  + foundItem.id
              window.open(`${window.location.origin}${link}`, '_blank');
              break
            }
            case 'express': {
              link = this.$store.getters.GET_PATHS.expressOrderEdit + '/' +
                  this.getExpressTypeById(foundItem.delivery_service_id).name + '/' +
                  foundItem.id
              window.open(`${window.location.origin}${link}`, '_blank');
              break
            }
            case 'consolidation': {
              link = this.$store.getters.GET_PATHS.ordersConsolidationEditing + '/' +
                  this.getConsolidationTypeByDestinationId(foundItem.consolidation_destination_id) + '/' + foundItem.id
              window.open(`${window.location.origin}${link}`, '_blank');
              break
            }
            case 'inbound': {
              link = this.$store.getters.GET_PATHS.ordersShipmentWarehouse + '/' +
                  this.getOrderType(foundItem['fulfillment'], foundItem['return']).value + '/' + foundItem.id
              window.open(`${window.location.origin}${link}`, '_blank');
              break
            }
            default:
              break
          }

        })

        console.log(type);
      }
    }
  }
</script>

<style scoped>

</style>