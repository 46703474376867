<template>
  <div class="show-more-btn btn-style">
    <div class="show-more-btn__txt"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <span class="admin-edit" @click="editTranslate(['common_showMore'])"></span>
      {{ $t('common_showMore.localization_value.value') }}
      <template v-if="count">
        ({{count}})
      </template>
    </div>
  </div>
</template>

<script>
  export default {
    name: "ShowMore",

    props: ['count'],
  }
</script>

<style lang="scss">
  @import "../../../../scss/colors";
  @import "../../../../scss/mixins/mixins";

  .show-more-btn{
    &__txt{
      position: relative;
      padding-right: 18px;
      font-weight: 500;
      font-size: 18px;
      line-height: 27px;
      color: $brown;

      @include for-768{
        font-size: 14px;
      }

      &:after{
        content: "";
        position: absolute;
        right: 0;
        top: 10px;
        display: block;
        width: 12px;
        height: 6px;
        background: url("../../../../assets/img/common/arr-brown.svg") center center no-repeat;
      }
    }
  }
</style>
