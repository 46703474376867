<template>
  <transition
          name="show-hide"
          v-if="showFilter"
  >
    <div class="table-filter__inner">
      <div class="table-filter" >
        <div class="table-filter__title">
          {{title}}
          <slot name="header"></slot>
        </div>
        <div class="table-filter__close btn-style" @click="closeFilter">
          {{ $t('common_close.localization_value.value') }}
        </div>

        <div class="table-filter__items" ref="filter">
          <slot name="body">

          </slot>
        </div>
        <div class="table-filter__btn">
          <slot name="footer">

          </slot>
        </div>
      </div>
    </div>

  </transition>

</template>

<script>

  export default {
    name: "FilterBlock",

    components: {
    },

    props: [
      'title',
      'showFilter'
    ],

    watch: {
      showFilter: function(newVal) {
        if(newVal) {
          setTimeout(() => {
            this.$refs.filter.addEventListener('keydown', (e) => {
              if(e.key === "Enter"){
                this.$emit('changeFilter');
              }
            })
          },1)
        } else {
          this.$refs.filter.removeEventListener('keydown', (e) => {
            if(e.key === "Enter"){
              this.$emit('changeFilter');
            }
          })
        }

      }
    },

    beforeDestroy() {
      if(this.$refs.filter && this.$refs.filter.getAttribute('listener') === 'true'){
        this.$refs.filter.removeEventListener('keydown', (e) => {
          if(e.key === "Enter"){
            this.$emit('changeFilter');
          }
        })
      }
    },

    data() {
      return {
        currentShow: false,
        animateShow: false,
        filter: false,
      }
    },

    mounted() {
      this.currentShow = this.showFilter

      this.filter = this.$refs.filter

      if(this.showFilter){
        this.$refs.filter.addEventListener('keydown', (e) => {
          if(e.key === "Enter"){
            this.$emit('changeFilter');
          }
        })
      }
    },

    methods: {
      closeFilter() {
        this.$emit('close');
      },

      // enter: function (el, done) {
      //   console.log(33);
      //   console.log(el);
      //   console.log(done);
      // },
      // leave: function (el, done) {
      //   console.log(44);
      //   console.log(el);
      //   console.log(done);
      // }
    },

    // watch: {
    //   showFilter: function(newVal) {
    //     if(newVal) {
    //       this.currentShow = true
    //       setTimeout(() => {
    //         this.animateShow = true
    //       }, 1)
    //     }
    //   }
    // }
  }
</script>

<style lang="scss">

  .show-hide-enter-active{
    transition: 0.5s ease-in;
    max-height: 0;
    overflow: hidden;
  }

  .show-hide-enter-to{
    max-height: 500px;
  }

  .show-hide-leave-active {
    max-height: 500px;
    transition: 0.5s ease-out;
  }


  .show-hide-leave-to {
    max-height: 0;
    overflow: hidden;
  }


</style>
