<template>
  <div class="empty-list "
       v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate(),
             'no-data' : countFilterParams > 0}">
    <span class="admin-edit"
          @click="editTranslate(['common_noResult', 'common_noDataYet', 'common_noResultSeems', 'common_noDataYetTxt', 'common_resetFilters'])"></span>
    <!--{{ $t('notification_noSearchResults.localization_value.value') }}-->

    <div class="empty-list__title" v-if="countFilterParams > 0">
      {{ titleWithFilter }}
    </div>
    <div class="empty-list__title" v-else>
      {{ title }}
    </div>

    <div class="empty-list__txt" v-if="countFilterParams > 0"
         v-html="textWithFilter">
    </div>
    <div class="empty-list__txt" v-else v-html="text">
    </div>

    <div class="empty-list__btn" v-if="countFilterParams > 0">
      <MainButton
          class="secondary btn-fit-content"
          :value="$t('common_resetFilters.localization_value.value')"
          @click.native="$emit('resetFilter')"
      />
    </div>
  </div>
</template>

<script>
import MainButton from "@/components/UI/buttons/MainButton/MainButton";

export default {
  name: "NoResult",

  components: {
    MainButton
  },

  props: [
      'countFilterParams',
      'title',
      'titleWithFilter',
      'text',
      'textWithFilter',
  ]
}
</script>

<style scoped>

</style>
