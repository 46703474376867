<template>
  <modal
      @close="$emit('close')"
      class="custom-popup"
  >
    <template slot="header">
      <template v-if="title">
        <span
            v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit"
                @click="editTranslate([title])"></span>

          {{$t(title + '.localization_value.value')}}
        </span>
      </template>
    </template>
    <template slot="body">
      <div class="custom-popup__content"
           v-if="txt.length > 0"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit"
                @click="editTranslate([txt])"></span>
        <template v-if="txt.length > 0" >
          <p class="fsz14" v-html="$t(txt + '.localization_value.value')">

          </p>
        </template>
      </div>
    </template>
    <template slot="footer">
      <div class="buy-label__btn d-flex align-items-center mt-4"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <span class="admin-edit"
                @click="editTranslate(['common_close'])"></span>

        <MainButton
            :value="$t('common_close.localization_value.value')"
            class="buy-label__btn-i wfc "
            @click.native="$emit('close')"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from "../../../commonModals/Modal";
  import MainButton from "../../../UI/buttons/MainButton/MainButton";

  export default {
    name: "InformationPopup",

    components: {
      Modal,
      MainButton,
    },

    props: {
      title: String,
      txt: String,
    },

    data() {
      return{
        data: {},
      }
    },

    methods: {
    },

  }
</script>

<style lang="scss">

</style>
