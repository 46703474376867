<template>
  <div class="label-circle" v-bind:class="[color ? 'label-circle--' + color : '']">
    <template v-if="value.length === 1">
      <span>
        {{value}}
      </span>
    </template>
    <template v-else>
      {{value}}
    </template>

  </div>
</template>

<script>
  export default {
    name: "LabelCircle",

    props: [
      'color',
      'value'
    ],
  }
</script>

<style lang="scss">
  @import "../../../scss/colors";

  .label-circle{
    border-radius: 50%;
    width: 16px;
    min-width: 16px;
    height: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: white;
    vertical-align: middle;
    line-height: 13px;
    font-size: 10px;
    font-weight: 500;
    text-align: center;
    position: relative;

    span{
      line-height: 0px;
      font-size: 10px;
      text-align: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-weight: normal;
    }


    &.oval{
      width: fit-content;
      padding: 0 5px;
      border-radius: 60px;
    }

    &--violet{
       background-color: $violet;
     }

    &--blue{
      background-color: $blue;
      /*font-size: 11px;*/
      /*line-height: 13px;*/
      /*padding-right: 1px;*/
    }

    &--orange{
      background-color: $orange;
      /*padding-right: 1px;*/
      /*font-size: 10px;*/
      /*line-height: 13px;*/
    }

    &--gray-light{
      background-color: $silver;
    }

    &--gray{
      background-color: $gray2;
      /*padding-right: 1px;*/
      /*font-size: 11px;*/
      /*line-height: 13px;*/
    }

    &--brown{
      background-color: $borderBrown;
      /*padding-right: 1px;*/
      /*padding-top: 1px;*/
      /*font-size: 11px;*/
      /*line-height: 13px;*/
    }

    &--green{
      background-color: $lightgreen;
    }

    &--red{
      background-color: $red;
    }
  }

</style>
