<template>
  <div class="export-btn btn-style"
       v-bind:class="{'export-btn--loading': $store.getters.getExportBtnLoading}"
  >
    <div @click="toggleExportDropdownShow" class="export-btn__txt">
      {{$t('common_export.localization_value.value')}}
    </div>
    <div v-if="exportDropdownShow" class="export-btn__list">
<!--      <template v-if="!customType">-->
        <div @click="downloadFiles('xlsx')" class="export-btn__item">Excel</div>
        <div @click="downloadFiles('csv')" class="export-btn__item">CSV</div>
<!--      </template>-->

      <template v-if="customType">
        <template v-for="(type, index) in customType">
          <div class="export-btn__item"
               :key="index"
              @click="downloadFiles(type.url_param)">
            {{ type.name }}
          </div>
        </template>
      </template>
    </div>
  </div>
</template>

<script>

  export default {
    name: "ExportBtn",

    props: {
      customType: {
        type: [Object, Array],
        default: null
      }
    },


    data() {
      return {
        exportDropdownShow: false,
      }
    },

    methods: {
      toggleExportDropdownShow() {
        this.exportDropdownShow = !this.exportDropdownShow
      },

      downloadFiles(type) {
        this.exportDropdownShow = false
        this.$emit('downloadFiles', type)
      }
    }
  }
</script>

<style lang="scss">
  @import "../../../../scss/colors";
  @import "../../../../scss/mixins/mixins";

  .export-btn{
    position: relative;
    padding: 3px 5px;

    &__txt{
      position: relative;
      padding-right: 20px;
      padding-left: 27px;
      font-weight: 500;
      font-size: 18px;
      line-height: 21px;
      color: $accent;
      transition: 0.3s;

      @include for-768{
        font-size: 14px;
      }

      &:before{
        content: "";
        position: absolute;
        left: 0;
        top: 1px;
        display: block;
        width: 17px;
        height: 17px;
        background: url("../../../../assets/img/common/export-btn.svg") center center no-repeat;
      }

      &:after{
        content: "";
        position: absolute;
        right: 0;
        top: 7px;
        display: block;
        width: 12px;
        height: 6px;
        background: url("../../../../assets/img/common/arr-brown-light.svg") center center no-repeat;
      }
    }

    &--loading{
      filter: grayscale(1);
      pointer-events: none;
    }

    &__list {
      position: absolute;
      bottom: 100%;
      right: 0;
      width: 100%;
      background-color: $mainBg;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      border-right: 1px solid rgba(0, 0, 0, 0.1);
      z-index: 21;
    }

    &__item {
      padding: 8px 5px;
      background-color: $mainBg;
      color: $accent;
      font-weight: bold;
      transition: 0.3s;

      &:hover {
        background-color: $bgTableEven;
      }
    }
  }
</style>
